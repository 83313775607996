import { Fragment, useEffect } from 'react';
import { Categories } from './categories/Categories';
import { Container } from './indexStyele';
import { Deals } from './deals/Deals';
import { PersonalizedUniqueSection } from './personalizedUnique/PersonalizedUnique';
import { BestSeller } from './bestSellers/BestSeller';
import { MainService } from './grooming/Index';
import { Feeds } from './feeds/Feeds';
import { Brands } from './brands/Brands';
import { Footer } from './footerSection/FooterSection';
import { Reviews } from './reviews/Reviews';
import { Box } from '@mui/material';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { HeaderBanner } from './banner/Banner';
import { useLocation } from 'react-router-dom';

const Landing = () => {
    const isMobile = useCheckMobileScreen();
    const location = useLocation();
    const hash = location.hash.substring(1);

    useEffect(() => {
        const element = document.getElementById('Top-brands');
        if (!element) return;
        if (hash === 'Top-brands') {
            const offset = element.offsetTop - 120;

            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    }, [location]);

    return (
        <Fragment>
            <HeaderBanner />
            <Container>
                <Categories />
                <Deals />
                <PersonalizedUniqueSection />
                <BestSeller />
            </Container>
            <Box sx={{ background: '#fff', padding: isMobile ? '40px 0' : '80px 0 120px' }}>
                <Brands />
                <MainService />
            </Box>

            <Reviews />
            <Feeds />
            <Footer />
        </Fragment>
    );
};

export default Landing;
