export const IndexContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        padding: '0 20px',
        gap: '20px',
        marginTop: '40px',
    }
}

export const ContainerBox = {
    width: '100%',
    top: 200,
    position: 'sticky',
    '@media (max-width: 768px)': {
        padding: '0 !important',
        position: 'unset',
    }
}

export const Container = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '590px',
    background: '#196761',
    padding: '64px 76px 0 90px',
    justifyContent: 'center',
    gap: '5rem',
    borderRadius: '40px',
    '@media (max-width: 1440px)': {
        height: 'auto',
    },
    '@media (max-width: 768px)': {
        padding: '28px 39px 0 28px',
        gap: '28px',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: "1.5rem 1.5rem 0 0",
        height: 'auto',
    }
};

export const MainContainer = {
    display: 'flex',
    flexDirection: 'column',
}

export const GroomingBox = {
    display: 'flex',
    padding: '8px 18px 8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '57px',
    background: '#FFF',
    width: 'max-content',
}

export const GroomingText = {
    color: '#231F20',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '18px',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
        fontSize: '14px',
    }
}

export const Title = {
    color: '#FFF',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '38px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '50px',
    letterSpacing: '1.52px',
    textTransform: 'capitalize',
    maxWidth: '504.94px',
    margin: '14px 0 34px',
    '@media (max-width: 768px)': {
        fontSize: '1.75rem',
        letterSpacing: '1.12px',
        lineHeight: '36px',
        margin: '12px 0 20px',
    }
}

export const ButtonStyle = {
    borderRadius: '12px',
    background: '#FFD600 !important',
    gap: '4px',
    padding: '14px 18px',
    marginBottom: '33px',
    '@media (max-width: 768px)': {
        marginBottom: 0,
    }
}

export const ButtonText = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
    }
}

export const Span = {
    lineHeight: '24px',
    fontWeight: 400,
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontStyle: 'italic',
    fontSize: '20px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
    }
}

export const ListMainContainer = {
    width: '487px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2.25rem',
    paddingBottom: '3rem',
    '@media (max-width: 768px)': {
        width: '100%',
        gap: '1.5rem',
        paddingBottom: 0,
    }
}

export const ListBox = {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
}

export const ListTextBox = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    '@media (max-width: 768px)': {
        gap: '6px',
    }
}

export const ListTitle = {
    color: '#FFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '0.4px',
    marginTop: '-3px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        letterSpacing: '0.32px',
    }
}

export const ListSubTitle = {
    color: 'rgba(255, 255, 255, 0.90)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    '@media (max-width: 768px)': {
        fontSize: '0.75rem',
        lineHeight: '1rem',
    }
}

export const ViewButton = {
    color: '#FFF',
    display: 'flex',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.32px',
    padding: '15px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    backgroundColor: '#196761',
    borderTop: '1px solid #227C75',
    width: '100%',
    borderRadius: "0 0 1.5rem 1.5rem",
}