import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, Typography } from '@mui/material';
import { consumeSignInUpCode } from '../../service/api/auth';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/loginHeader.svg';
import {
    ChangePhoneBox,
    ChangePhoneIcon,
    CloseBox,
    LogoBox,
    ModalStyle,
    OtpInfo,
    PhoneInfo,
    ResendBox,
    ResendButton,
    ResendSpan,
    SpanInfo,
} from './LoginStyle';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { getRewardsAction } from '../../service/action/userActions';
import { useDispatch } from 'react-redux';

export default function Login({
    handleLoginModalAction,
    open,
    preAuthSessionId,
    deviceId,
    phone,
    sendOTP,
    orderData,
    createAddressMut,
    createOrderMut,
    sendOtpActionStatus,
    cartItems,
    paymentMethod,
    setCurrStep,
}: any) {
    const [isInvalidOTP, setIsInvalidOTP] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (!canResend) {
            timer = setInterval(() => {
                setResendTimer(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setCanResend(true);
                        return 30;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [canResend]);

    const verifyOTPFormMut = useMutation({
        mutationFn: (data: any) => consumeSignInUpCode(data),
        onSuccess: (res: any) => {
            const { data, headers } = res;

            if (res?.data?.status === 'OK') {
                // Store tokensFF
                localStorage.setItem('tempAccessToken', headers['st-access-token']);

                setIsInvalidOTP(false);

                if (orderData.address.id) {
                    createOrderMut.mutate({
                        metaData: {
                            notes: orderData?.notes,
                        },
                        name: orderData?.name,
                        phone: orderData?.phone.replace(/^0+/, ''),
                        addressId: orderData.address.id,
                        paymentMode: paymentMethod,
                        cartSessionId: cartItems ? cartItems.id : '',
                    });
                } else {
                    const result = createAddressMut.mutate({
                        address1: orderData?.address.address_line_one,
                        address2: orderData?.address.address_line_two,
                        name: `${orderData?.user.firstName} ${orderData?.user.lastName}`,
                        phone: orderData?.user?.phoneNumber.replace(/^0+/, ''),
                        city: orderData?.address?.city,
                        state: orderData?.address?.state,
                        pinCode: orderData?.address?.pincode,
                        country: orderData?.address?.country,
                        metaData: {
                            latitude: 0,
                            longitude: 0,
                        },
                        email: orderData?.user?.email,
                    });
                }

                /* recall api to get updated rewards and points */
                dispatch(getRewardsAction({}));
            } else {
                setIsInvalidOTP(true);
                if (res.data.status === 'RESTART_FLOW_ERROR') {
                }
            }
        },
        onError: (res: any) => {
            setIsInvalidOTP(true);
        },
    });

    const submitVerifyOtp = async (values: any) => {
        const payload = {
            userInputCode: values.otp,
            deviceId,
            preAuthSessionId,
        };

        const result = verifyOTPFormMut.mutate(payload);
        return;
    };

    const closeModal = () => {
        if (
            !verifyOTPFormMut.isPending &&
            !createAddressMut.isPending &&
            !createOrderMut.isPending
        ) {
            handleLoginModalAction();
        }
    };

    const changePhoneNumber = () => {
        if (
            !verifyOTPFormMut.isPending &&
            !createAddressMut.isPending &&
            !createOrderMut.isPending
        ) {
            navigate('/order', {
                state: {
                    changePhoneNumber: true,
                },
            });
            setCurrStep(1);
            handleLoginModalAction();
        }
    };

    const resendOTP = ({ phoneNumber }: any) => {
        if (
            !verifyOTPFormMut.isPending &&
            !createAddressMut.isPending &&
            !createOrderMut.isPending
        ) {
            sendOTP({ phoneNumber });
        }
    };

    return (
        <Box>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={e => handleLoginModalAction()}
                closeAfterTransition
                BackdropComponent={({ children, ...props }) => (
                    <Box
                        {...props}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                    </Box>
                )}
            >
                <Fade in={open}>
                    <Box sx={ModalStyle}>
                        <Formik
                            initialValues={{
                                otp: '',
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                otp: Yup.string()
                                    .matches(/^[0-9]{6}$/, 'Must be a valid OTP')
                                    .required('OTP is required'),
                            })}
                            onSubmit={submitVerifyOtp}
                            validateOnBlur
                            validateOnMount
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container gap={'1.5rem'}>
                                        <Grid item xs={12} sx={LogoBox}>
                                            <Box sx={CloseBox}>
                                                <CloseIcon
                                                    sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                                    onClick={closeModal}
                                                />
                                            </Box>
                                            <img src={logo} alt="" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={OtpInfo}>
                                                <Typography sx={PhoneInfo}>
                                                    Get verified, Enter the OTP sent to +91-
                                                    {phone}
                                                </Typography>
                                                <Typography sx={SpanInfo}>
                                                    To confirm the order please verify your contact
                                                    number
                                                </Typography>
                                            </Box>

                                            <Typography
                                                onClick={changePhoneNumber}
                                                sx={ChangePhoneBox}
                                            >
                                                <Button sx={ChangePhoneIcon}>
                                                    <Icon color="#0A69D9" icon="mynaui:edit-one" />
                                                </Button>
                                                Change Phone no.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    id="otp-login"
                                                    type="text"
                                                    value={values.otp}
                                                    name="otp"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter OTP"
                                                    fullWidth
                                                    error={Boolean(touched.otp && errors.otp)}
                                                />

                                                {touched.otp && errors.otp && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        {errors.otp}
                                                    </FormHelperText>
                                                )}
                                                {isInvalidOTP && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        Entered OTP is not valid.
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                disableElevation
                                                disabled={
                                                    verifyOTPFormMut.isPending ||
                                                    createAddressMut.isPending ||
                                                    createOrderMut.isPending
                                                }
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                {verifyOTPFormMut.isPending ||
                                                createAddressMut.isPending ||
                                                createOrderMut.isPending ? (
                                                    <>
                                                        <CircularProgress
                                                            size={15}
                                                            sx={{ mr: 1 }}
                                                            color="inherit"
                                                        />
                                                        Placing your order
                                                    </>
                                                ) : (
                                                    'Confirm'
                                                )}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={ResendBox}>
                                                <Typography sx={ResendSpan}>
                                                    Didn’t get the OTP?
                                                </Typography>
                                                <Button
                                                    onClick={() =>
                                                        resendOTP({
                                                            phoneNumber: phone.replace(/^0+/, ''),
                                                        })
                                                    }
                                                    variant="text"
                                                    color="primary"
                                                    sx={ResendButton}
                                                >
                                                    {canResend ? (
                                                        sendOtpActionStatus ? (
                                                            <>
                                                                <CircularProgress
                                                                    size={15}
                                                                    sx={{ mr: 1 }}
                                                                    color="inherit"
                                                                />
                                                                <Typography sx={ResendSpan}>
                                                                    Sending...
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            'Resend OTP'
                                                        )
                                                    ) : (
                                                        `Retry in 00:${
                                                            resendTimer < 10
                                                                ? '0' + resendTimer
                                                                : resendTimer
                                                        }`
                                                    )}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}
