import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import giftIcon from '../../assets/icons/gift.svg';
import {
    CloseBox,
    LogoBox,
    ModalStyle,
    OtpInfo,
    PhoneInfo,
    ResendBox,
    ResendButton,
    ResendSpan,
    SpanInfo,
} from '../auth/LoginStyle';
import { redeemVerify } from '../../service/api/rewards';
import { getRewardsAction } from '../../service/action/userActions';
import { useDispatch } from 'react-redux';
export default function RedeemRewardModal({
    handleClose,
    openModal,
    phone,
    sendOTP,
    selectedCouponCode,
    sendOtpActionStatus,
    handleCouponCodeOpen,
    setCouponData,
}: any) {
    const [isError, setIsError] = useState('');
    const [resendTimer, setResendTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);
    const dispatch = useDispatch();

    let count = 0; // added counter condition for local ENV
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        count = count + 1;
        if (count === 1) {
            sendOTP();
        }
    }, []);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (!canResend) {
            timer = setInterval(() => {
                setResendTimer(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setCanResend(true);
                        return 30;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [canResend]);

    const verifyOTPFormMut = useMutation({
        mutationFn: (data: any) => redeemVerify(data),
        onSuccess: (res: any) => {
            if (res.data.coupon) {
                setCouponData((prev: any) => {
                    return {
                        ...prev,
                        id: res?.data?.coupon?.id,
                        code: res?.data?.coupon?.code,
                        expirationDate: res?.data?.coupon?.expirationDate,
                    };
                }); // set generated coupon data
                handleClose();
                handleCouponCodeOpen();
                dispatch(getRewardsAction({})); // Get rewards details to update user reward points
            } else {
                if (Array.isArray(res.data.message)) {
                    setIsError('Something went wront, Please try again');
                } else {
                    setIsError(res.data.message);
                }
            }
        },
        onError: (res: any) => {
            console.log('ERROR', res, Array.isArray(res.data.message));
            if (Array.isArray(res.data.message)) {
                setIsError('Something went wront, Please try again');
            } else {
                setIsError(res.data.message);
            }
        },
    });

    const submitVerifyOtp = async (values: any) => {
        const payload = {
            otp: values.otp,
            rewardId: selectedCouponCode,
        };

        const result = verifyOTPFormMut.mutate(payload);
        return;
    };

    function formatPhoneNumber() {
        if (!phone?.startsWith('+91')) {
            return 'Invalid number';
        }

        // Extract the country code and the main number
        const countryCode = phone?.slice(0, 3);
        const mainNumber = phone?.slice(3);

        return countryCode + '-' + mainNumber;
    }

    return (
        <Box>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={({ children, ...props }) => (
                    <Box
                        {...props}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                    </Box>
                )}
            >
                <Fade in={openModal}>
                    <Box sx={ModalStyle}>
                        <Formik
                            initialValues={{
                                otp: '',
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                otp: Yup.string()
                                    .matches(/^[0-9]{4}$/, 'Must be a valid OTP')
                                    .required('OTP is required'),
                            })}
                            onSubmit={submitVerifyOtp}
                            validateOnBlur
                            validateOnMount
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container gap={'1.5rem'}>
                                        <Grid item xs={12} sx={LogoBox}>
                                            <Box sx={CloseBox}>
                                                <CloseIcon
                                                    sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                                    onClick={handleClose}
                                                />
                                            </Box>
                                            <img src={giftIcon} alt="" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ ...OtpInfo, alignItems: 'center' }}>
                                                <Typography sx={{ ...PhoneInfo, fontSize: '23px' }}>
                                                    To Unlock reward, Enter the OTP sent to{' '}
                                                    {formatPhoneNumber()}
                                                </Typography>
                                                <Typography sx={SpanInfo}>
                                                    Verify your contact no, and redeem your reward.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    id="otp-login"
                                                    type="text"
                                                    value={values.otp}
                                                    name="otp"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter OTP"
                                                    fullWidth
                                                    error={Boolean(touched.otp && errors.otp)}
                                                />

                                                {touched.otp && errors.otp && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        {errors.otp}
                                                    </FormHelperText>
                                                )}
                                                {isError && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        {isError}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                disableElevation
                                                disabled={verifyOTPFormMut.isPending}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                {verifyOTPFormMut.isPending ? (
                                                    <>
                                                        <CircularProgress
                                                            size={15}
                                                            sx={{ mr: 1 }}
                                                            color="inherit"
                                                        />
                                                        Verying...
                                                    </>
                                                ) : (
                                                    'Confirm'
                                                )}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={ResendBox}>
                                                <Typography sx={ResendSpan}>
                                                    Didn’t get the OTP?
                                                </Typography>
                                                <Button
                                                    onClick={sendOTP}
                                                    variant="text"
                                                    color="primary"
                                                    sx={ResendButton}
                                                >
                                                    {canResend ? (
                                                        sendOtpActionStatus ? (
                                                            <>
                                                                <CircularProgress
                                                                    size={15}
                                                                    sx={{ mr: 1 }}
                                                                    color="inherit"
                                                                />
                                                                <Typography sx={ResendSpan}>
                                                                    Sending...
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            'Resend OTP'
                                                        )
                                                    ) : (
                                                        `Retry in 00:${
                                                            resendTimer < 10
                                                                ? '0' + resendTimer
                                                                : resendTimer
                                                        }`
                                                    )}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}
