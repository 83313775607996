import { IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { searchInput } from '../product/productStyle';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const SearchBox = () => {
    const isMobile = useCheckMobileScreen();
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            window.location.href = `/products/?search=${encodeURIComponent(inputValue)}`;
        }
    };

    const handleSearchIconPress = (event: any) => {
        window.location.href = `/products/?search=${encodeURIComponent(inputValue)}`;
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryValue = queryParams.get('search');

        if (queryValue) {
            setInputValue(queryValue);
        }
    }, []);

    return (
        <Stack
            direction="row"
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flex: '1 0 0',
                width: '100%',
            }}
        >
            <OutlinedInput
                sx={{
                    ...searchInput,
                    borderRadius: '12px',
                    background: '#FFF',
                    padding: isMobile ? '1rem' : '14px 16px',
                }}
                value={inputValue}
                type="text"
                size="small"
                placeholder="Search eg. wet food"
                fullWidth
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                inputProps={{
                    sx: {
                        '&::placeholder': {
                            color: '#656363',
                        },
                    },
                }}
                endAdornment={
                    <InputAdornment position="start" sx={{ margin: '0' }}>
                        <IconButton
                            edge="start"
                            onClick={handleSearchIconPress}
                            sx={{
                                margin: 0,
                                padding: 0,
                                '&:hover': {
                                    backgroundColor: 'unset',
                                },
                            }}
                        >
                            <SearchIcon sx={{ color: '#231F20' }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Stack>
    );
};

export default SearchBox;
