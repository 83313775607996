import { Box, Divider, Grid, Typography } from '@mui/material';
import Icon from '../../component/icon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    CopyRight,
    CopyRightText,
    MenuTitle,
    FooterContainer,
    Title,
    SupportTime,
    MainContainer,
    FollowStyle,
    MenuItems,
    IconGrid,
    FollowBox,
    FollowLinkBox,
    MenuGrid,
    MenuItemsBox,
    NeedHelpText,
    HelpGrid,
    Support,
    SupportTitle,
    DividerStyle,
    MoreDetails,
} from './footerStyle';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { FooterSections } from '../../common/utils/navItem';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isPhone = useCheckMobileScreen();

    const handleNavItem = (url: string) => {
        window.location.href = url;
    };

    const handleCustomerSupport = () => {
        navigate('/support');
        handleScrollToTop();
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <MainContainer>
                {!(isPhone && location.pathname.includes('order')) && (
                    <Box sx={FooterContainer}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3} sx={IconGrid}>
                                <Icon name="logo-yellow" />
                                <Typography sx={Title}>
                                    Sniffy Nose is one stop solution for all your pet needs,
                                    providing all the services at your door-step.
                                </Typography>
                                <Box sx={FollowBox}>
                                    <Typography sx={FollowStyle}>Follow us on:</Typography>
                                    <Box sx={FollowLinkBox}>
                                        <Link
                                            to={'https://www.facebook.com/sniffynose.sn/'}
                                            target="_blank"
                                        >
                                            <Icon name="facebook" />
                                        </Link>
                                        <Link
                                            to={
                                                'https://www.youtube.com/channel/UCIL2YBN6TXEOSh8SctqMkGA'
                                            }
                                            target="_blank"
                                        >
                                            <Icon name="youtube" />
                                        </Link>
                                        <Link
                                            to={'https://www.instagram.com/sniffynose.sn/'}
                                            target="_blank"
                                        >
                                            <Icon name="instagram" />
                                        </Link>
                                    </Box>
                                </Box>
                            </Grid>

                            {FooterSections.map((section, index) => (
                                <Grid item xs={6} sm={6} md={1.6} key={index} sx={MenuGrid}>
                                    <Typography sx={MenuTitle}>{section.title}</Typography>
                                    <Box sx={MenuItemsBox}>
                                        {section.tags.map((tag: any, idx: any) => (
                                            <Link
                                                to={tag.path}
                                                key={idx}
                                                onClick={e => handleNavItem(tag.path)}
                                                style={MenuItems}
                                            >
                                                {tag.label}
                                            </Link>
                                        ))}
                                    </Box>
                                </Grid>
                            ))}

                            <Grid item xs={12} sm={6} md={2.5} sx={HelpGrid}>
                                <Typography sx={NeedHelpText}>Need Help?</Typography>
                                <Box sx={{ width: 'max-content' }}>
                                    <Typography sx={SupportTitle}>Visit our</Typography>
                                    <Link
                                        to="/support"
                                        style={Support}
                                        onClick={handleCustomerSupport}
                                    >
                                        Customer support
                                    </Link>
                                    <Divider sx={DividerStyle} />
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={SupportTitle}>Call us: </Typography>
                                        <Typography sx={SupportTime}>9AM - 7PM support</Typography>
                                    </Box>
                                    <Link style={Support} to={'tel:9359939912'}>
                                        +91 935 - 993 - 9912
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={CopyRight}>
                            <Typography sx={CopyRightText}>
                                Copyrights © 2023 | All rights reserved to Sniffy Nose
                            </Typography>
                            <Link to="#" style={MoreDetails}>
                                More details
                            </Link>
                        </Box>
                    </Box>
                )}
            </MainContainer>
        </>
    );
};

export default Footer;
