import { Box, Skeleton, Stack, Tab, Tabs, Typography, styled } from '@mui/material';
import React from 'react';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { Title, filterTitle } from '../view/product/productStyle';
import Image from 'mui-image';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import FilterTag from '../view/product/FilterTag';

type ProductFilterDesktopProps = {
    filterVals: any;
    showOnMobile?: Boolean;
    isLoading: Boolean;
    filterId: number | string;
    setCurrFilterId: any;
    categoryData: any;
    setProductsParams: any;
    productsParams: any;
};

type Props = {
    filterVals: any;
    isLoading: Boolean;
    currFilterId: number | string;
    setCurrFilterId: any;
    categoryData: any;
    productsParams: any;
    setProductsParams: any;
    title: string;
    clearSearchFilter: any;
    resetUrlFilter: any;
};

export const ProductCatTabs = styled(Tabs)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '.MuiTabs-scrollButtons': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
    },
    '.MuiTabs-scrollButtons:first-of-type': {
        left: 0,
    },
    '.MuiTabs-scrollButtons:last-of-type': {
        right: 0,
    },
    '.MuiSvgIcon-root': {
        width: '2rem',
        height: '2rem',
        borderRadius: '50px',
        border: '1px solid #ECECEC',
        background: '#F6F6F6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ProductCatTab = styled(Tab)(({ theme }) => ({
    gap: '0.625rem',
    textTransform: 'none',
    marginRight: '1.875rem',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.85rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    width: '7.5rem',
    padding: '0 0 0.75rem 0',
    '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontWeight: 600,
    },
    '@media (max-width: 768px)': {
        width: '5rem',
        height: '6rem',
        minWidth: '4rem',
        marginRight: '1.5rem',
        gap: '0.5rem',
        fontSize: '0.65rem',
        padding: '0 0 0.5rem 0',
    },
}));

export function ProductFilterDesktop({
    filterVals,
    isLoading,
    filterId,
    setCurrFilterId,
    setProductsParams,
    productsParams,
}: ProductFilterDesktopProps) {
    const isMobile = useCheckMobileScreen();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setProductsParams({
            ...productsParams,
            search_keyword: '',
        });

        setCurrFilterId(newValue);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <ProductCatTabs
                value={!isLoading ? filterId : null}
                onChange={handleChange}
                scrollButtons="auto"
                variant="scrollable"
                sx={{
                    borderBottom: isMobile ? 'none' : 1,
                    borderColor: 'divider',
                    borderBlockColor: 'rgba(204, 204, 204, 0.50)',
                    width: '100%',
                    paddingTop: isMobile ? '1.75rem' : 'auto',
                }}
            >
                {isLoading ? (
                    <Stack direction={'row'} spacing={2} paddingTop={isMobile ? '0' : '1.75rem'}>
                        {Array(20)
                            .fill(null)
                            .map(() => (
                                <Skeleton
                                    variant="rounded"
                                    width={isMobile ? '4.5rem' : '7.3125rem'}
                                    height={isMobile ? '5rem' : '8.3125rem'}
                                />
                            ))}
                    </Stack>
                ) : (
                    filterVals?.map(({ _id, name, slug, media }: any) => (
                        <ProductCatTab
                            key={_id}
                            value={slug}
                            icon={
                                <Image
                                    src={media?.url}
                                    height={isMobile ? '3.891875rem' : '6.875rem'}
                                    width={isMobile ? '4.6rem' : '5.4rem'}
                                    duration={1000}
                                    fit="contain"
                                    alt={`store ${_id}`}
                                />
                            }
                            label={name}
                        />
                    ))
                )}
            </ProductCatTabs>
        </Box>
    );
}

export default function CategoryFilter({
    currFilterId,
    setCurrFilterId,
    filterVals,
    isLoading,
    categoryData,
    productsParams,
    setProductsParams,
    title,
    clearSearchFilter,
    resetUrlFilter,
}: Props) {
    const isMobile = useCheckMobileScreen();
    return (
        <>
            <ProductFilterDesktop
                filterVals={filterVals}
                isLoading={isLoading}
                filterId={currFilterId}
                setCurrFilterId={setCurrFilterId}
                categoryData={categoryData}
                setProductsParams={setProductsParams}
                productsParams={productsParams}
            />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    paddingTop: { xs: '1.5rem', md: '1rem' },
                    paddingBottom: { xs: '0.75rem', md: '1.5rem' },
                }}
                mb={1}
                spacing={2}
            >
                <Grid
                    sx={{
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        gap: isMobile ? '10px' : '',
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: '100%',
                        height: isMobile ? 'auto' : '48px',
                    }}
                >
                    <Stack
                        spacing={1}
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'flex-start' : 'center',
                            gap: isMobile ? 0 : '10px',
                        }}
                    >
                        <Typography sx={filterTitle}>
                            <Title>{title}</Title>
                        </Typography>
                        <Box ml={isMobile ? '' : '1rem'}>
                            {productsParams.tags && (
                                <FilterTag
                                    removeFilter={resetUrlFilter}
                                    title={productsParams.tags}
                                    filterType={'tag'}
                                />
                            )}
                            {productsParams.coupon_code && (
                                <FilterTag
                                    removeFilter={resetUrlFilter}
                                    title={productsParams.coupon_code}
                                    filterType={'coupon_code'}
                                />
                            )}
                        </Box>
                    </Stack>
                    {/* <Stack direction="row" width={isMobile ? '100%' : 'auto'}>
                        <OutlinedInput
                            sx={searchInput}
                            type="text"
                            size="small"
                            value={productsParams?.search_keyword || ''}
                            onChange={e =>
                                setProductsParams({
                                    ...productsParams,
                                    search_keyword: e.target.value,
                                })
                            }
                            placeholder="Search eg. wet food"
                            fullWidth
                            startAdornment={
                                <InputAdornment position="start" sx={{ margin: '0' }}>
                                    <IconButton
                                        edge="start"
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'unset',
                                            },
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            endAdornment={
                                productsParams.search_keyword ? (
                                    <InputAdornment position="end" sx={{ margin: '0' }}>
                                        <IconButton
                                            edge="end"
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'unset',
                                                },
                                            }}
                                        >
                                            <CancelIcon
                                                onClick={clearSearchFilter}
                                                sx={{ color: 'darkgray' }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </Stack> */}
                </Grid>
            </Stack>
        </>
    );
}
