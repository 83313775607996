export const ContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    '@media (max-width: 768px)': {
        marginTop: '3rem',
    }
};

export const TitleStyle = {
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '2rem',
    color: '#14141E',
    textAlign: 'center',
    fontWeight: 700,
    letterSpacing: '1.28px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    }
};

export const SubTitleStyle = {
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '1.125rem',
    textAlign: 'center',
    color: '#14141E',
    letterSpacing: '0.72px',
    '@media (max-width: 768px)': {
        fontSize: '14px',
        letterSpacing: '0.56px',
    }
};

export const ProductContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '3.25rem',
    marginTop: '1.25rem',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: 0,
    }
};

export const ItemTitleBox = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,
    position: 'relative',
    paddingTop: '60px',
    '@media (max-width: 768px)': {
        paddingTop: '20px',
    }
}

export const SubTitle = {
    color: '#10182B',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.72px',
    paddingBottom: '6px',
    '@media (max-width: 768px)': {
        paddingBottom: 0,
    },
}

export const ItemTitle = {
    color: '#221818',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '53px',
    letterSpacing: '1.44px',
    textTransform: 'uppercase',
    textAlign: 'center',
    textShadow: '4px 2px 0px rgba(0, 0, 0, 0.15)',
    width: 'min-content',
    '@media (max-width: 768px)': {
        lineHeight: '40px',
        fontSize: '36px',
    }
}

export const ItemIconBox = {
    padding: '8px 8px 8px 14px',
    background: '#FFF',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    marginTop: '15px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
        margin: '10px',
        padding: '8px',
    }
}

export const ShopNowButton = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
}