import { appColor } from "../../../common/appColor/appColor";

export const Container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px 0 147px',
    width: '100%',
    gap: '3rem',
    backgroundColor: `${appColor.white}`,
    '@media (max-width: 768px)': {
        padding: '58px 0',
        gap: '2rem',
    }
};

export const TitleBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '@media (max-width: 768px)': {
        gap: '4px'
    }
}

export const Title = {
    color: '#14141E',
    textAlign: 'center',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '1.28px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    }
}

export const SubTitle = {
    color: '#14141E',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.72px',
    '@media (max-width: 768px)': {
        fontSize: '14px',
        letterSpacing: '0.56px',
    }
}


export const ImageBox={
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    width: '100%',
    gap: '2rem',
    padding: '0 10px',
    '@media (max-width: 768px)': {
        gap: '1rem',
        paddingLeft: '20px',
        justifyContent: 'start',
    }
}
