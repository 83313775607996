import { Box, Typography } from '@mui/material';
import Icon from '../../../component/icon';
import {
    MainContainer,
    Title,
    SubTitle,
    TitleBox,
    GoogleText,
    ReviewsText,
    ReviewsTextBox,
    RatingCount,
    RatingBox,
    RatingIcons,
    GoogleCustomerType,
    GoogleCustomerName,
    GoogleComment,
    GoogleCommentBox,
    GoogleCommentContainer,
    Container,
    GoogleCommentMainContainer,
    GoogleCommentIconBox,
    CurveStyle,
    ReviewsBox,
    BlurSection,
} from './reviewStyle';
import Image from '../../../component/image/image';
import Rating from '../../../assets/icons/Rating-star.svg';
import HalfRating from '../../../assets/icons/half-Rating.svg';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import StarIcon from '@mui/icons-material/Star';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getReviews } from '../../../service/api/googleReviews';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { reviewRating } from '../utils';

interface Review {
    comment: string;
    reviewer: {
        profilePhotoUrl: string;
        displayName: string;
    };
}
let averageRating = 0;
let totalReviewCount = 0;

export function Reviews() {
    const isMobile = useCheckMobileScreen();
    const [reviews, setReviews] = useState<Review[]>([]);
    const getReviewMut = useMutation({
        mutationFn: (data: any) => getReviews(),
        onSuccess: (res: any) => {
            /* get result */
            const result = res?.data?.reviews || [];
            const firstHalf = result.slice(0, Math.ceil(result.length / 2));
            const secondHalf = result.slice(Math.ceil(result.length / 2));
            /* make chunk of reviews */
            const allReviews = [firstHalf, secondHalf];
            /* set state */
            setReviews(allReviews);
            averageRating = res?.data?.averageRating;
            totalReviewCount = res?.data?.totalReviewCount;
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET REVIEWS', error);
        },
    });

    useEffect(() => {
        /* api call to get all google reviews */
        getReviewMut.mutate({});
    }, []);

    return (
        <>
            <Box
                sx={{
                    ...CurveStyle,
                    borderRadius: '0 0 50% 50%/ 0 0 95% 93%',
                    marginTop: '-3px',
                }}
            />

            <Box sx={MainContainer}>
                <Box sx={Container}>
                    <Box>
                        <Box>
                            <Icon name="Smile" />
                        </Box>
                        <Box sx={TitleBox}>
                            <Typography sx={Title}>
                                Trusted by over 5000+ customers Since 2018.
                            </Typography>
                            <Typography sx={SubTitle}>
                                Don’t just trust one side of the story. Here’s what our customers
                                have said about us.
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={RatingBox}>
                                <Typography sx={RatingCount}>{averageRating.toFixed(1)}</Typography>
                                <Box sx={RatingIcons}>
                                    {[1, 2, 3, 4].map((i: any) => (
                                        <Image
                                            key={i}
                                            src={Rating}
                                            alt=""
                                            style={{ width: '35px', height: '35px' }}
                                        />
                                    ))}
                                    <Image src={HalfRating} alt="" />
                                </Box>
                            </Box>
                            <Box sx={ReviewsTextBox}>
                                <Typography sx={ReviewsText}>
                                    {totalReviewCount.toFixed(0)}+ Reviews
                                </Typography>
                                <Typography sx={GoogleText}>on</Typography>
                                <Icon name="Google" />
                                <Typography sx={GoogleText}>Google</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={GoogleCommentMainContainer}>
                        <Box>
                            {!isMobile && <Box sx={BlurSection} />}
                            <Box sx={GoogleCommentContainer}>
                                {reviews.map((reviews: any, i: any) => (
                                    <Box
                                        sx={{
                                            ...ReviewsBox,
                                            animation:
                                                i === 0
                                                    ? 'scrollTop 38s linear infinite'
                                                    : 'scrollTop 40s linear infinite',
                                            marginTop: i === 1 ? '-15px' : '',
                                        }}
                                    >
                                        {reviews.map((item: any, ind: any) => (
                                            <Box mb={2} sx={GoogleCommentBox}>
                                                <Box>
                                                    {Array(reviewRating(item?.starRating || ''))
                                                        .fill(0)
                                                        .map((_, index) => (
                                                            <StarIcon
                                                                key={index}
                                                                sx={{ color: '#EE6B34' }}
                                                            />
                                                        ))}
                                                    <Typography sx={GoogleComment}>
                                                        {item?.comment || 'NA'}
                                                    </Typography>
                                                </Box>
                                                <Box sx={GoogleCommentIconBox}>
                                                    {item?.reviewer?.profilePhotoUrl ? (
                                                        <Image
                                                            width={'40'}
                                                            height={'40'}
                                                            src={item?.reviewer?.profilePhotoUrl}
                                                        />
                                                    ) : (
                                                        <AccountCircleIcon
                                                            sx={{
                                                                color: '#EFF1F2',
                                                                fontSize: '2.5rem',
                                                            }}
                                                        />
                                                    )}

                                                    <Box>
                                                        <Typography sx={GoogleCustomerName}>
                                                            {item?.reviewer?.displayName}
                                                        </Typography>
                                                        <Typography sx={GoogleCustomerType}>
                                                            {'Customer'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                            {!isMobile && (
                                <Box
                                    sx={{
                                        ...BlurSection,
                                        transform: 'rotate(180deg)',
                                        marginTop: '-255px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    ...CurveStyle,
                    borderRadius: '50% 50% 0 0/95% 93% 0 0',
                    marginTop: isMobile ? '-35px' : '-63px',
                }}
            />
        </>
    );
}
