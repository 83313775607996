export const MainContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F9F8F6',
    padding: '80px 0',
    '@media (max-width: 768px)': {
        padding: '60px 20px',
    }
};

export const Container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '1440px',
    width: '100%',
    gap: '110px',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        padding: 0,
    },
};

export const TopContainer = {
    display: 'flex',
    gap: '130px',
    alignItems: 'center',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '40px',
    }
 }

export const TitleBox = {
    display: 'flex',
}

export const Title = {
    color: '#14141E',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '58px',
    letterSpacing: '1.76px',
    maxWidth: '340px',
    '@media (max-width: 768px)': {
        fontSize: '2rem',
        letterSpacing: '1.28px',
        maxWidth: '300px',
        lineHeight: '42px',
    }
}

export const Description = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
    letterSpacing: '-0.18px',
    padding: '1rem 0 2rem',
    maxWidth: '395px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        letterSpacing: '-0.16px',
        lineHeight: '24px',
        padding: '12px 0 2rem',
        width: '100%',
    }
}

export const ButtonStyle = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    padding: '14px 18px',
    boxShadow: 'none !important',
    background: '#FDDC2E !important',
    borderRadius: '12px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        padding: '8px 18px',
    }
}

export const TipBoxStyle = {
    display: 'flex',
    gap: '28px',
    '@media (max-width: 768px)': {
        flexDirection: 'row-reverse',
        gap: '14px',
    }
};

export const tipTitleStyle = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '25px',
    letterSpacing: '-0.36px',
    maxWidth: '363px',
    '@media (max-width: 768px)': {
        fontSize: '14px',
        width: '100%',
        lineHeight: '20px',
        letterSpacing: '-0.28px'
    }
}

export const TipMainContainer = {
    paddingLeft: "84px",
    borderLeft: '1px solid rgba(169, 148, 116, 0.20)',
    display: 'flex',
    flexDirection: 'column',
    gap: '60px',
    '@media (max-width: 768px)': {
        padding: '0',
        gap: '28px',
        borderLeft: 'none',
        borderTop: '1px solid rgba(169, 148, 116, 0.20)',
        paddingTop: '3rem',
    }
}

export const TipContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
}

export const ReadMoreButton = {
    padding: 0,
    color: '#0A69D9',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    display: 'flex',
    height: '20.822px',
    alignItems: 'center',
    gap: '4px',
}

export const BottomContainer = {
    borderRadius: '40px',
    background: '#222A34',
    width: '100%',
    display: 'flex',
    paddingRight: '70px',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        padding: 0,
    }
}

export const BottomTitle = {
    color: 'var(--white, #FFF)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '45px',
    width: '390px',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
        width: '100%',
        fontSize: '28px',
        lineHeight: '36px',
        letterSpacing: '1.12px',
    }
}

export const BottomLeft = {
    padding: '69px 0 69px 80px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    '@media (max-width: 768px)': {
        padding: '28px',
        gap: '20px',
    }
}

export const BottomLeftImageBox = {
    height: '56px',
    display: 'flex',
    gap: '1rem',
    '@media (max-width: 768px)': {
        gap: '12.647px',
    }
}

export const ImageBox = {
    '@media (max-width: 768px)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

export const QRCodeBox = {
    display: 'inline-flex',
    transform: 'rotate(-13.271deg)',
    padding: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '26px',
    border: '11px solid rgba(10, 9, 6, 0.73)',
    background: '#FFF',
    position: 'absolute',
    marginLeft: '-311px',
    marginTop: '55px',
}

export const DownloadText = {
    color: '#332306',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18.167px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '19.464px',
    letterSpacing: '-0.363px',
    width: '80px',
    paddingBottom: '10px'
}
