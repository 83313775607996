import { Box, Tab, Tabs, Typography } from '@mui/material';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import styled from '@emotion/styled';
import Image from 'mui-image';
import { container, Title, CategoryTitle, CategorySub, LabelBox } from './category';
import { useNavigate } from 'react-router-dom';
import { CategoryData } from '../utils';

export const ProductCatTabs = styled(Tabs)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '.MuiTabs-flexContainer': {
        justifyContent: 'space-between',
    },
    '.MuiTab-root': {
        minWidth: '19.7%',
        flexShrink: 0,
    },
    '@media (max-width: 1200px)': {
        '.MuiTab-root': {
            minWidth: '24.7%',
        },
    },
    '@media (max-width: 900px)': {
        '.MuiTab-root': {
            minWidth: '33.33%',
        },
    },
    '.MuiTabs-scrollButtons:first-of-type': {
        left: 0,
        marginLeft: '-5px',
        marginRight: '1.5rem',
        opacity: 1,
    },
    '.MuiTabs-scrollButtons:last-of-type': {
        marginRight: '-5px',
        marginLeft: '1.5rem',
        right: 0,
        opacity: 1,
    },
    '.MuiSvgIcon-root': {
        width: '2rem',
        height: '2rem',
        borderRadius: '50px',
        border: '1px solid #ECECEC',
        background: '#F6F6F6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ProductCatTab = styled(Tab)(({ theme }) => ({
    gap: '21.2px',
    textTransform: 'none',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '0 0 0.75rem 0',
    color: 'var(--Black, #14141E)',
    '@media (max-width: 768px)': {
        marginRight: '2.375rem',
        gap: '1rem',
        fontSize: '1rem',
        padding: '0 0 0.5rem 0',
    },
}));

export function Categories() {
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    return (
        <Box sx={container}>
            <Typography sx={Title}>Specially curated for you</Typography>
            <ProductCatTabs scrollButtons="auto" variant="scrollable" sx={{ width: '100%' }}>
                {CategoryData.map((category: any, i: any) => (
                    <ProductCatTab
                        key={category.name}
                        value={category.name}
                        onClick={e => navigate(category.link)}
                        icon={
                            <Image
                                src={category.image}
                                height={isMobile ? '5rem' : '7.75rem'}
                                width={isMobile ? '6.875rem' : '9.1875rem'}
                                duration={1000}
                                fit="contain"
                                alt={`store ${category.name}`}
                            />
                        }
                        label={
                            <Box sx={LabelBox}>
                                <Typography sx={CategoryTitle}>{category.name}</Typography>
                                <Typography sx={{ fontSize: '0.8rem', color: '#888' }}>
                                    <span style={{ ...CategorySub, fontWeight: '400' }}>
                                        {category.description}
                                    </span>
                                    <span style={CategorySub}> {category.info} </span>
                                </Typography>
                            </Box>
                        }
                    />
                ))}
            </ProductCatTabs>
        </Box>
    );
}
