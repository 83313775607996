import { Card, CardContent, Typography } from '@mui/material';
import {
    TermsConditionsCard,
    TermsConditionsCardContent,
    TermsConditionsText,
    TermsConditionsTextIcon,
} from './RewardStyle';

export default function TermsConditions() {
    
    return (
        <Card variant="outlined" sx={TermsConditionsCard}>
            <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>Terms & Conditions</Typography>
            <CardContent sx={TermsConditionsCardContent}>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> A minimum purchase is
                    required to qualify for this offer.
                </Typography>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> Points will expire after
                    3 months from the last purchase if not redeemed.
                </Typography>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> Multiple coupons cannot
                    be applied to a single order, and coupons are not valid for promotional offers.
                </Typography>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> The merchant reserves
                    the right to make the final decision and modify or cancel this offer at any time
                    without notice.
                </Typography>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> Product images may vary
                    from the actual product received.
                </Typography>
                <Typography sx={TermsConditionsText}>
                    <Typography sx={TermsConditionsTextIcon}>~</Typography> Free products are only
                    added to the cart when purchasing with other items.
                </Typography>
            </CardContent>
        </Card>
    );
}
