import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { appColor } from "../../common/appColor/appColor";

export const Container = styled(Box)`
    box-sizing: border-box;
    width: 100%;
    background-color: ${appColor.white};
    border-radius: 50px 50px 0 0;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-top: -50px;
    @media (max-width: 768px) {
        margin-top: -20px;
        border-radius: 25px 25px 0 0;
        padding: 60px 20px 0;
    }
`;