import { Fragment } from "react";
import Layout from "../component/layout/layout";
import Landing from "../view/landing";

const Home = () => {
  return (
    <Fragment>
      <Layout>
        <Landing />
      </Layout>
    </Fragment>
  );
};

export default Home;
