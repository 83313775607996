import { Box, Tab, Tabs, Typography } from '@mui/material';
import {
    Container,
    MainContainer,
    TabTextBox,
    Title,
    TitleBox,
    BrandName,
    BrandOffer,
    TabContainer,
} from './brandStyle';
import styled from '@emotion/styled';
import { BrandsData } from '../utils';
import Image from 'mui-image';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import BrandLeft from '../../../assets/images/brandLeft.webp';
import BrandRight from '../../../assets/images/brandRight.webp';
import { useEffect, useRef } from 'react';

export const ProductCatTabs = styled(Tabs)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.MuiTabs-scrollButtons': {
        position: 'absolute',
        top: '75%',
        zIndex: 2,
        '@media (max-width: 768px)': {
            display: 'flex',
        },
    },
    '.MuiTabs-scrollButtons:first-of-type': {
        marginRight: '4rem',
        opacity: 1,
        '@media (max-width: 768px)': {
            marginRight: '2.5rem',
        },
    },
    '.MuiTabs-scrollButtons:last-of-type': {
        marginLeft: '4rem',
        opacity: 1,
        '@media (max-width: 768px)': {
            marginLeft: '2.5rem',
        },
    },
    '.MuiSvgIcon-root': {
        width: '3rem',
        height: '3rem',
        borderRadius: '50px',
        border: '1px solid #ECECEC',
        background: '#F6F6F6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            width: '2rem',
            height: '2rem',
        },
    },
}));

const ProductCatTab = styled(Tab)(({ theme }) => ({
    gap: '1.875rem',
    textTransform: 'none',
    marginRight: '4.125rem',
    padding: '0 0 0.75rem 0',
    '@media (max-width: 768px)': {
        marginRight: '1.75rem',
        gap: '1rem',
        padding: '0 0 0.5rem 0',
    },
}));

export function Brands() {
    const isMobile = useCheckMobileScreen();
    const myRef = useRef<HTMLElement | null>(null);

    return (
        <Box sx={MainContainer} ref={myRef} id="Top-brands">
            <Box sx={Container}>
                <Box sx={TitleBox}>
                    <Image
                        src={BrandLeft}
                        alt=""
                        width={isMobile ? '38.257px' : '58.955px'}
                        height={isMobile ? '23.79px' : '34.873px'}
                    />
                    <Typography sx={Title}>Top Brands</Typography>
                    <Image
                        src={BrandRight}
                        alt=""
                        width={isMobile ? '38.257px' : '58.955px'}
                        height={isMobile ? '23.79px' : '34.873px'}
                    />
                </Box>
                <Box sx={TabContainer}>
                    <ProductCatTabs
                        scrollButtons="auto"
                        variant="scrollable"
                        sx={{
                            width: '100%',
                            paddingBottom: isMobile ? '5rem' : '140px',
                        }}
                    >
                        {BrandsData?.map((brand, index) => (
                            <ProductCatTab
                                onClick={e =>
                                    (window.location.href = `/products/?search=${brand.name}`)
                                }
                                icon={
                                    <Image
                                        src={brand.image}
                                        height={isMobile ? '5.5rem' : ''}
                                        width={isMobile ? '6.875rem' : ''}
                                        fit="contain"
                                        alt={brand.name}
                                    />
                                }
                                key={index}
                                label={
                                    <Box sx={TabTextBox}>
                                        <Typography sx={BrandName}>{brand.name}</Typography>
                                        <Typography sx={BrandOffer}>{brand.offer}</Typography>
                                    </Box>
                                }
                            />
                        ))}
                    </ProductCatTabs>
                </Box>
            </Box>
        </Box>
    );
}
