import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    ConsultText,
    DesktopItemLabel,
    StyledNewText,
    StyleLink,
    StyleNewText,
} from './headerStyle';
import { Box, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { BrandsData, DesktopNavItems } from '../landing/utils';
import { styled } from '@mui/material/styles';

const CustomMenuContainer = styled('div')(() => ({
    position: 'relative',
    display: 'inline-block',
}));

const CustomMenu = styled('div')(() => ({
    display: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#fff',
    width: 'max-content',
    minWidth: '140px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    '& a': {
        color: '#000',
        padding: '12px 16px',
        textDecoration: 'none',
        display: 'block',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
}));

const CustomMenuItem = styled('div')(() => ({
    padding: '12px 19px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#ddd',
    },
}));

const DesktopNavLinks = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const location = useLocation();
    const hash = location.hash.substring(1);
    const redirectTo = (url: string, event: React.MouseEvent<HTMLElement>) => {
        if (url || hash !== 'Top-brands') {
            window.location.href = url;
        }
    };

    return (
        <>
            {DesktopNavItems.map(item => (
                <CustomMenuContainer
                    key={item.label}
                    onMouseEnter={item.label === 'Shop Brands' ? handleMenuOpen : undefined}
                    onMouseLeave={item.label === 'Shop Brands' ? handleMenuClose : undefined}
                    onClick={e => {
                        if (item.label === 'Consult a Vet' || item.label === 'Shop Brands') {
                            return;
                        } else {
                            redirectTo(item.path, e);
                        }
                    }}
                >
                    <Link
                        to="javascript:void(0)"
                        style={{ ...StyleLink, textDecoration: 'none', color: 'black' }}
                    >
                        <Typography sx={DesktopItemLabel}>{item.label}</Typography>

                        {item.label === 'Grooming' && (
                            <Box sx={StyleNewText}>
                                <AutoAwesomeIcon sx={{ color: '#FF7700', fontSize: '14px' }} />
                                <Typography sx={StyledNewText}>NEW</Typography>
                            </Box>
                        )}

                        {item.label === 'Consult a Vet' && (
                            <Typography
                                sx={{
                                    ...DesktopItemLabel,
                                    ...ConsultText,
                                    border: '1px solid rgba(255, 119, 0, 0.20)',
                                    background: 'rgba(255, 119, 0, 0.30)',
                                    marginLeft: '4px',
                                }}
                            >
                                Soon
                            </Typography>
                        )}

                        {item.label === 'Shop Brands' && (
                            <CustomMenu style={{ display: anchorEl ? 'block' : 'none' }}>
                                {BrandsData.map((menu: any) => (
                                    <CustomMenuItem
                                        key={menu.label}
                                        onClick={() =>
                                            (window.location.href = `/products/?search=${menu.name}`)
                                        }
                                    >
                                        {menu.name}
                                    </CustomMenuItem>
                                ))}
                            </CustomMenu>
                        )}
                    </Link>
                </CustomMenuContainer>
            ))}
        </>
    );
};

export default DesktopNavLinks;
