import { Box, Grid, Typography } from '@mui/material';
import ToyImg from '../../assets/images/toy.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
    ProductNotAvailableLabel,
    ProductNotAvailableSemiLabel,
    ProductNotFoundBox,
    ProductNotFoundGrid,
    StyledLink,
    WhatsAppBtn,
} from './productStyle';

const ProductNotFound = () => {
    const link =
        'https://api.whatsapp.com/send/?phone=+919359939912&text=Hey!%20Can%20you%20give%20me%20a%20paw-some%20assist?%20My%20human%20needs%20a%20hand!&type=phone_number&app_absent=0';

    return (
        <Grid xs={12} sx={ProductNotFoundGrid}>
            <Box sx={ProductNotFoundBox}>
                <img src={ToyImg} alt="" />
                <Box sx={{ gap: '12px' }}>
                    <Typography sx={ProductNotAvailableLabel}>
                        Product Not Available at our online store!
                    </Typography>
                    <Typography sx={ProductNotAvailableSemiLabel}>
                         You can check availability on our offline stores via WhatsApp
                    </Typography>
                </Box>

                <StyledLink to={link} target="_blank" aria-label="Chat with us on WhatsApp">
                    <WhatsAppIcon style={WhatsAppBtn} /> Connect on WhatsApp
                </StyledLink>
            </Box>
        </Grid>
    );
};

export default ProductNotFound;
