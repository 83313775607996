export const BestSellerContent: { [key: string]: any[] } = {
    'For-Dog': [
        {
            title: 'Food',
            description: 'Dry Food, Wet Food, Puppy Food, Grain Free Food',
            discountTitle: '20% OFF',
            image: require('../../assets/images/dogFood.webp'),
            link: '/products/?category=dog food',
        },
        {
            title: 'Treats',
            description: 'Biscuits & Cookies, Bones & Jerky Treats',
            discountTitle: '25% OFF',
            image: require('../../assets/images/dogTreat.webp'),
            link: '/products/?category=dog treats',
        },
        {
            title: 'Grooming',
            description: 'Shampoos, Brushes, Combs, Slickers Towels & Wipes',
            discountTitle: '50% OFF',
            image: require('../../assets/images/dogGrooming.webp'),
            link: '/products/?tag=dog grooming',
        },
        {
            title: 'Accessories',
            description: 'Collars, Leashes, Harnesses, Bow Ties & Bandanas',
            discountTitle: '25% OFF',
            image: require('../../assets/images/dogAccessories.webp'),
            link: '/products/?tag=dog accessories',
        },
    ],
    'For-Cat': [
        {
            title: 'Food',
            description: 'Dry Food, Wet Food, Kitten Food, Diet Food & More',
            discountTitle: '20% OFF',
            image: require('../../assets/images/catFood.webp'),
            link: '/products/?category=cat food',
        },
        {
            title: 'Treats',
            description: 'Natural Treats, Creamy Treats, Healthy Treats & More',
            discountTitle: '25% OFF',
            image: require('../../assets/images/catTreat.webp'),
            link: '/products/?tag=cat Treat',
        },
        {
            title: 'Accessories',
            description: 'Collars, Harnesses, Bandanas, Tents & Beds',
            discountTitle: '50% OFF',
            image: require('../../assets/images/catAccessories.webp'),
            link: '/products/?tag=cat accessories',
        },
        {
            title: 'Toy',
            description: 'Interactive Toys, Catnip, Plush Toys, Scratchers, Teaser & Wand',
            discountTitle: '25% OFF',
            image: require('../../assets/images/catToy.webp'),
            link: '/products/?tag=cat toy',
        },
    ],
};

export const BestSellerTabs = [
    { label: 'Dogs', value: 'For-Dog', icon: 'For-Dog', selectedIcon: 'Selected-Dog' },
    { label: 'Cats', value: 'For-Cat', icon: 'For-Cat', selectedIcon: 'Selected-Cat' },
];

export const GroomingItems = [
    {
        tagTitle: 'Pet Grooming',
        title: 'best Grooming where your furry babies shine!',
        button: 'Book Slot',
        borderColor: '#227C75',
        color: '#196761',
        image: require('../../assets/images/GroomingImage.webp'),
        FeatureList: [
            {
                icon: 'Home-Service',
                title: 'Home Service Available',
                subTitle:
                    'For your convenience, we also offer grooming services in the comfort of your own home.',
            },
            {
                icon: 'Pricing',
                title: 'Transparent Pricing',
                subTitle:
                    'Our pricing is straightforward and upfront, so you know exactly what to expect with no hidden fees.',
            },
            {
                icon: 'Pick-Drop',
                title: 'Convenient Pick & Drop',
                subTitle:
                    'We offer pick-up and drop-off services to make grooming even easier for busy pet parents.',
            },
            {
                icon: 'Paw-Care',
                title: 'Pampered Paws',
                subTitle:
                    'We offer a gentle and loving touch, ensuring your pet feels safe and comfortable throughout their grooming experience.',
            },
            {
                icon: 'Stylist',
                title: 'Expert Stylists',
                subTitle:
                    "Our experienced groomers are skilled in all breeds and styles, creating a look that suits your pet's personality and lifestyle.",
            },
        ],
    },
    {
        tagTitle: 'Online Pet Consultations',
        title: 'Connect with a Veterinarian Anytime, Anywhere.',
        color: '#803CC7',
        borderColor: '#9451DA',
        button: 'Coming soon',
        image: require('../../assets/images/Consult.webp'),
        FeatureList: [
            {
                icon: 'Personalized',
                title: 'Personalized Care',
                subTitle:
                    "Get tailored advice for your pet's unique needs from experienced veterinarians.",
            },
            {
                icon: 'Consultations',
                title: 'Quick & Convenient Consultations',
                subTitle:
                    'Access expert guidance within minutes, without the hassle of scheduling an appointment.',
            },
            {
                icon: 'Pricing',
                title: 'Affordable Care',
                subTitle:
                    'Enjoy cost-effective consultations without compromising the quality of care.',
            },
            {
                icon: 'Paw-Care',
                title: 'Peace of Mind',
                subTitle: 'Gain reassurance and peace of mind knowing your pet is in good hands.',
            },
            {
                icon: 'Recommendations',
                title: 'Prescription Assistance & Recommendations ',
                subTitle:
                    'If needed, your veterinarian can provide you with a prescription that you can fill at your local pharmacy.',
            },
        ],
    },
    {
        tagTitle: 'Tail-Wagging Walks',
        title: "Tailored Treks, Your Pup's Happy Place",
        color: '#0A69D9',
        borderColor: '#2280EF',
        button: 'Coming soon',
        image: require('../../assets/images/petWalk.webp'),
        FeatureList: [
            {
                icon: 'Walks',
                title: 'Personalized Walks',
                subTitle:
                    "Our experienced walkers match your dog's personality and energy level for the perfect outing.",
            },
            {
                icon: 'Scheduling',
                title: 'Flexible Scheduling',
                subTitle: "Choose the frequency and duration that suits your pet's lifestyle.",
            },
            {
                icon: 'Safe-Secure',
                title: 'Safe & Secure',
                subTitle:
                    "Insured walks and dedicated walkers ensure your pup's safety and well-being.",
            },
            {
                icon: 'Pupdates',
                title: 'Real-time Pupdates',
                subTitle:
                    "Get real-time updates about your pet's adventures, so you can relax knowing they're having a blast.",
            },
            {
                icon: 'Affordable',
                title: 'Affordable Rates',
                subTitle: 'Pay only for the walk time you choose, with no hidden fees.',
            },
        ],
    },
];

export const BrandsData = [
    {
        image: require('../../assets/images/acana.webp'),
        name: 'Acana',
        offer: 'Free Goodies',
    },
    {
        image: require('../../assets/images/royalCanin.webp'),
        name: 'Royal Canin',
        offer: 'Upto 18% OFF',
    },
    {
        image: require('../../assets/images/chipChop.webp'),
        name: 'CHIP CHOP',
        offer: 'Upto 25% OFF',
    },
    {
        image: require('../../assets/images/furlicks.webp'),
        name: 'Furlicks',
        offer: 'Upto 25% OFF',
    },
    {
        image: require('../../assets/images/Jerhigh.webp'),
        name: 'Jerhigh',
        offer: 'Upto 25% OFF',
    },
    {
        image: require('../../assets/images/Wahl.webp'),
        name: 'Wahl',
        offer: 'Upto 12% OFF',
    },
    {
        image: require('../../assets/images/Fofos.webp'),
        name: 'Fofos',
        offer: 'Upto 15% OFF',
    },
    {
        image: require('../../assets/images/Trixie.webp'),
        name: 'Trixie',
        offer: 'Upto 15% OFF',
    },
    {
        image: require('../../assets/images/Odourlock.webp'),
        name: 'Odourlock',
        offer: 'Upto 10% OFF',
    },
    {
        image: require('../../assets/images/TrueLove.webp'),
        name: 'TrueLove',
        offer: 'Upto 20% OFF',
    },
    {
        image: require('../../assets/images/Himalaya.webp'),
        name: 'Himalaya',
        offer: 'Upto 15% OFF',
    },
];

export const TipsData = [
    {
        icon: require('../../assets/images/Dental Care.webp'),
        title: 'A Healthy Smile for Your Furry Friend: A Guide to Pet Dental Care',
        link: 'https://blog.sniffynose.com/2024/09/04/a-healthy-smile-for-your-furry-friend-a-guide-to-pet-dental-care/',
    },
    {
        icon: require('../../assets/images/Litter Training.webp'),
        title: 'Litter Training Your Kitten: A Step-by-Step Guide',
        link: 'https://blog.sniffynose.com/2024/09/04/litter-training-your-kitten-a-step-by-step-guide/',
    },
    {
        icon: require('../../assets/images/bondingIcon.webp'),
        title: 'A Pawsome Guide to Caring for Your New Family Member',
        link: 'https://blog.sniffynose.com/2024/09/05/a-pawsome-guide-to-caring-for-your-new-family-member/',
    },
];

export const DesktopNavItems = [
    {
        label: 'Dogs',
        path: '/products/?search=dogs',
        icon: '',
    },
    {
        label: 'Cats',
        path: '/products/?search=cats',
        icon: '',
    },
    {
        label: 'Shop Brands',
        path: '/#Top-brands',
        icon: 'KeyboardArrowDownIcon',
    },
    {
        label: 'Customize products',
        path: '/products/?tag=Custom Products',
        icon: '',
    },
    {
        label: 'Grooming',
        path: '/service',
        icon: '',
    },
    {
        label: 'Stores',
        path: '/location',
        icon: '',
    },
    {
        label: 'Consult a Vet',
        path: '',
        icon: '',
    },
];

export const personalized = [
    {
        title: 'Personalized Bandanas',
        subTitle: 'Give Your Pet a Unique Touch with',
        image: require('../../assets/images/bandana.webp'),
        imageGif: require('../../assets/images/bandanaGif.gif'),
        link: 'products/?tag=customized products',
    },
    {
        title: 'Twinning t-shirts',
        subTitle: 'Match with Your Pet in Style',
        image: require('../../assets/images/twinning tshirts.webp'),
        imageGif: '',
        link: 'products/?tag=customized products',
    },
];

export const SliderBanner = [
    {
        id: 1,
        image: 'https://sniffynose-assets.s3.amazonaws.com/banners/Banner1.webp',
        imageMob: 'https://sniffynose-assets.s3.amazonaws.com/banners/BannerMob1.webp',
        alt: 'Banner 1',
    },
    {
        id: 2,
        image: 'https://sniffynose-assets.s3.amazonaws.com/banners/Banner2.webp',
        imageMob: 'https://sniffynose-assets.s3.amazonaws.com/banners/BannerMob2.webp',
        alt: 'Banner 2',
    },
    {
        id: 3,
        image: 'https://sniffynose-assets.s3.amazonaws.com/banners/Banner3.webp',
        imageMob: 'https://sniffynose-assets.s3.amazonaws.com/banners/BannerMob3.webp',
        alt: 'Banner 3',
    },
    {
        id: 4,
        image: 'https://sniffynose-assets.s3.amazonaws.com/banners/Banner4.webp',
        imageMob: 'https://sniffynose-assets.s3.amazonaws.com/banners/BannerMob4.webp',
        alt: 'Banner 4',
    },
];

export const CategoryData = [
    {
        name: 'Steal Deals',
        image: require('../../assets/images/stealDeal.webp'),
        description: 'Steal the Best Deals',
        info: '',
        link: 'products/?tag=Steal Deals',
    },
    {
        name: 'Budget Friendly',
        image: require('../../assets/images/budgetFriendly.webp'),
        description: 'Starting from ₹199',
        info: '',
        link: 'products/?tag=Budget Friendly',
    },
    {
        name: 'Combo Packs',
        image: require('../../assets/images/Combo.webp'),
        description: 'Up to ',
        info: '25% OFF',
        link: 'products/?category=Combos',
    },
    {
        name: 'Premium Foods',
        image: require('../../assets/images/premiumFood.webp'),
        description: 'Always a Choice',
        info: '',
        link: 'products/?tag=Premium Foods',
    },
    {
        name: 'Treats',
        image: require('../../assets/images/treats.webp'),
        description: 'Up to ',
        info: '25% OFF',
        link: 'products/?category=Treats',
    },
    {
        name: 'Tie & Dye T-shirt',
        image: require('../../assets/images/tshirt.webp'),
        description: 'Starting from ₹599',
        info: '',
        link: 'products/?tag=Tie Dye T-shirt',
    },
    {
        name: 'Toys',
        image: require('../../assets/images/toy.webp'),
        description: 'Under ₹499',
        info: '',
        link: 'products/?category=Toys',
    },
    {
        name: 'Furlicks',
        image: require('../../assets/images/categoryFurlicks.webp'),
        description: 'Up to ',
        info: '15% OFF',
        link: 'products/?search=Furlicks',
    },
    {
        name: 'SN Exclusive',
        image: require('../../assets/images/exclusive.webp'),
        description: 'Up to ',
        info: '25% OFF',
        link: 'products/?tag=SN Exclusive',
    },
    {
        name: 'Supplements',
        image: require('../../assets/images/supplement.webp'),
        description: 'Up to ',
        info: '15% OFF',
        link: 'products/?category=Supplements',
    },
];

export const reviewRating = (rating: string) => {
    const arrCount: { [key: string]: number } = {
        ONE: 1,
        TWO: 2,
        THREE: 3,
        FOUR: 4,
        FIVE: 5,
    };

    return arrCount[rating] || 5; // pass 5 as default rating, if not invalid rating found
};
