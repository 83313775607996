export const MainContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: '#032533',
    '@media (max-width: 768px)': {
        paddingBottom: '20px',
    }
};

export const Container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '1440px',
    width: '100%',
    gap: '3rem',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        padding: 0,
    },
};

export const TitleBox = {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.375rem',
    marginTop: '60px',
    '@media (max-width: 768px)': {
        marginTop: '40px',
        gap: '11px',
    }
}

export const TabContainer = {
    width: '100%',
    maxWidth: '1440px',
    
}

export const Title = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '1.28px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    }
}

export const TabTextBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    '@media (max-width: 768px)': {
        gap: '4px',
    }
}

export const BrandName = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    '@media (max-width: 768px)': {
        fontSize: '14px',
    }
}

export const BrandOffer = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.48px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        letterSpacing: '0.32px',
    }
}

