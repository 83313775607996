import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { appColor } from '../../common/appColor/appColor';
import { Link } from 'react-router-dom';

export const Container = styled(Box)`
    box-sizing: border-box;
    width: 100%;
    background-color: ${appColor.white};
    border-radius: 50px;
    padding: 50px;
    padding-top: 0;
    margin-top: 170px;
    @media (max-width: 768px) {
        margin-top: 60px;
    }
`;

export const SearchFilter = {
    top: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const Search = styled(Box)`
    display: flex;
    border-radius: 0.75rem;
    opacity: var(--Cart, 1);
    background: #fff;
    box-shadow: 0px 0px 0px 7px rgba(217, 185, 135, 0.1);
`;

export const InputField = {
    display: 'flex',
    width: '27.75rem',
    height: '4rem',
    padding: '1rem 1.25rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.25rem',
    flexShrink: 0,
};

export const SearchIconButton = {
    borderRadius: '0.75rem',
    opacity: 'var(--Cart, 1)',
    background: 'var(--yellow-sec, #FDDC2E)',
    margin: '10px',
    ':hover': {
        background: 'var(--yellow-sec, #FDDC2E)',
    },
};

export const Categories = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 2.75rem;
    align-items: flex-start;
    overflow-x: scroll;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

export const Category = styled(Box)`
    display: flex;
    padding-bottom: 1.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-shrink: 0;
    opacity: var(--Cart, 1);
    width: 7.5rem;
    cursor: pointer;
`;

export const CategoryTitle = styled(Box)`
    color: var(--Black, #14141e);
    text-align: center;
    font-family: Euclid-Circular-A-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const CategoryImage = {
    width: '8.8125rem',
    height: '8.3125rem',
};

export const ProceedText = styled(Box)`
    overflow: hidden;
    color: var(--Black, #14141e);
    text-overflow: ellipsis;
    font-family: Euclid-Circular-A-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
`;

export const TitleBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-top: 1.88rem;
`;

export const Title = styled(Box)`
    color: var(--Black, #14141e);
    font-family: Euclid-Circular-A-Semi-Bold;
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
    @media (max-width: 768px) {
        color: var(--Black, #14141e);
        font-family: Cambon-Semi-Bold;
        font-size: 1.42963rem;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
    }
`;

export const ProductBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    borderRadius: '1.25rem',
};

export const ProductTitleAndDesBox = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
    opacity: var(--Cart, 1);
`;

export const ProductTitle = styled(Box)`
    color: var(--Black, #14141e);
    font-family: Euclid-Circular-A-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    align-self: stretch;
    opacity: var(--Cart, 1);
`;

export const ProductDes = styled(Box)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #747474;
    text-overflow: ellipsis;
    font-family: Euclid-Circular-A-Regular;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: var(--Cart, 1);
`;

export const ProductPriceAndCardBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const ProductPriceBox = styled(Box)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    opacity: var(--Cart, 1);
`;

export const ProductSellingPrice = styled(Box)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--Black, #14141e);
    text-overflow: ellipsis;
    font-family: Euclid-Circular-A-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 120% */
    opacity: var(--Cart, 1);
`;

export const ProductMrp = styled(Box)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--Black, #14141e);
    text-overflow: ellipsis;
    font-family: Euclid-Circular-A-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration-line: strikethrough;
    opacity: 0.4;
`;

export const ProductCard = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
    }
`;

export const Arrow = styled(Box)`
    display: flex;
    justify-content: center;
    margin-left: 350px;
    @media (max-width: 768px) {
        height: 3rem;
        transform: rotate(-90.208deg);
        margin-top: -25px;
        margin-left: 230px;
    }
`;

export const ButtonText = styled(Box)`
    color: #fff;
    font-family: Euclid-Circular-A-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.02rem;
`;

export const HeightLightImage = styled(Box)`
    width: 96%;
    height: 1.56338rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-top: -24.5px;
    margin-left: 90px;

    @media (max-width: 768px) {
        width: 9.625rem;
        height: 1.625rem;
        margin-top: -20px;
        margin-left: 9rem;
    }
`;

export const WhatsappButtonBox = styled(Box)`
    display: inline-flex;
    padding: 0.875rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: 0.5rem;
    background: #25d366;
    margin-top: -60px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 14.1875rem;
        padding: 0.9375rem 1rem;
        margin-top: 0px;
    }
`;

export const LoadMore = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '2rem',
};

export const ProductUnavailable = {
    textAlign: 'center',
    padding: '7rem 0',
};

export const LoadMoreButton = {
    display: 'inline-flex',
    padding: '18px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    height: '48px',
    borderRadius: '12px',
    border: 'var(--Cart, 1px) solid rgba(0, 0, 0, 0.10)',
    background: 'var(--white, #FFF)',
    color: 'var(--Black, #14141E)',
    textAlign: 'right',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px',
    '&:hover': {
        background: '#f9f9f9',
    },
    marginTop: '1rem',
};

export const filterTitle = {
    color: 'var(--Dark-brown, #241F1F)',
    textAlign: 'start',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const searchInput = {
    height: '48px',
    gap: '10px',
    '@media (max-width: 768px)': {
        width: '100%',
        height: '52px',
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,  
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'var(--Cart, 1px) solid rgba(204, 204, 204, 0.50)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'var(--Cart, 1px) solid rgba(204, 204, 204, 0.50)',
    },
};

export const filtersStyle = {
    display: 'flex',
    padding: '8px 10px 8px 14px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '60px',
    border: '1px solid rgba(204, 204, 204, 0.50)',
    color: '#0A69D9',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Matter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.16px',
};

export const ProductNotAvailableLabel = {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Fraunces',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '-0.28px',
};
export const ProductNotAvailableSemiLabel = {
    color: '#5C6370',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '26px',
};

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    gap: '8px',
    borderRadius: '12px',
    border: '1px solid #ECECEC',
    background: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 24px',
    color: '#05A441',
}));

export const WhatsAppBtn = {
    color: '#05A441',
    width: '24px',
    height: '24px',
    flexShrink: 0,
};

export const ProductNotFoundGrid = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
};
export const ProductNotFoundBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
};
