import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CURRENCY_SIGN } from '../../common/constants';
import Icon from '../../component/icon';
import { useSelector } from 'react-redux';
import { selectCartIsLoading, selectCartItems } from '../../service/selectors/cartSelectors';
import {
    AddMore,
    AvailableCouponCode,
    AvailableTitleText,
    CodeStyleForOffer,
    ExpiredLabelStyle,
    OfferContainer,
    OfferDes,
    OfferMainContainer,
    OfferTextBox,
    SpanStyle,
} from './CouponStyle';
import { checkCouponExpired } from '../../common/utils/couponUtils';

const Offer = ({ coupon, index, applyCoupon, isApplied, removeCoupon, isShowButtons }: any) => {
    const isCartLoading = useSelector(selectCartIsLoading);
    const cartItems = useSelector(selectCartItems);
    const [currSelectedId, setcurrSelectedId] = useState('');

    const totalAmount = cartItems.total || 0;

    useEffect(() => {
        /* clear state after action completed */
        if (isCartLoading === false && currSelectedId) {
            setcurrSelectedId('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCartLoading]);

    const formatDiscountAmt = () => {
        const discoountAmt = Number(coupon?.metaData?.minOrderValue - totalAmount);

        if (discoountAmt % 1 > 0) {
            return discoountAmt.toFixed(2);
        }
        return discoountAmt;
    };

    return (
        <Box
            sx={{
                ...OfferMainContainer,
                borderTop: index === 0 || isApplied ? '' : '1px solid rgba(204, 204, 204, 0.50)',
            }}
        >
            <Icon name={'discount-coupon'} />
            <Box sx={OfferContainer}>
                <Box sx={OfferTextBox}>
                    {isShowButtons ? (
                        <Typography sx={CodeStyleForOffer}>
                            {isApplied ? `‘${coupon.code}’ applied on this order` : coupon.code}
                        </Typography>
                    ) : (
                        <Typography sx={AvailableTitleText}>
                            Save{' '}
                            {coupon.discountMetric === 'PERCENTAGE' && coupon.discountValue + '% '}
                            Extra OFF
                            {coupon.discountMetric === 'INR' &&
                                ' upto ' + CURRENCY_SIGN + coupon.discountValue}
                        </Typography>
                    )}
                    {isShowButtons ? (
                        <>
                            {isApplied ? (
                                <Typography sx={OfferDes}>
                                    {CURRENCY_SIGN + coupon?.applicableDiscountInr + ' Saved'}
                                </Typography>
                            ) : (
                                <Box>
                                    <Typography sx={OfferDes}>
                                        Save{' '}
                                        {coupon.discountMetric === 'PERCENTAGE' &&
                                            coupon.discountValue + '% '}
                                        Extra OFF
                                        {coupon.discountMetric === 'INR' &&
                                            ' upto ' + CURRENCY_SIGN + coupon.discountValue}{' '}
                                        <span style={SpanStyle}>
                                            using this coupon
                                            {coupon?.metaData?.maxDiscountValue > 0 &&
                                                '. Max: ' +
                                                    CURRENCY_SIGN +
                                                    coupon?.metaData?.maxDiscountValue +
                                                    ' INR'}
                                        </span>
                                    </Typography>
                                    {totalAmount < coupon?.metaData?.minOrderValue && (
                                        <Typography sx={AddMore}>
                                            Add {CURRENCY_SIGN + formatDiscountAmt()} more to avail
                                            this offer
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </>
                    ) : (
                        <Typography sx={AvailableCouponCode}>
                            Use Code {coupon.code}{' '}
                            {coupon.metaData?.minOrderValue &&
                                `| Above
                        ${CURRENCY_SIGN + coupon.metaData?.minOrderValue}`}
                            {coupon.metaData?.maxDiscountValue &&
                                `. Max Discount
                        ${CURRENCY_SIGN + coupon.metaData?.maxDiscountValue}`}
                        </Typography>
                    )}
                </Box>
                <Box>
                    {isShowButtons && (
                        <Box>
                            {isApplied ? (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="error"
                                    sx={{
                                        color: '#FF4E16',
                                        background: 'rgba(255, 78, 22, 0.10)',
                                        '&:hover': {
                                            color: '#FFF',
                                        },
                                    }}
                                    disabled={isCartLoading && coupon?.id === currSelectedId}
                                    onClick={e => {
                                        removeCoupon();
                                        setcurrSelectedId(coupon?.id);
                                    }}
                                >
                                    {isCartLoading && coupon?.id === currSelectedId ? (
                                        <>
                                            <CircularProgress
                                                size={15}
                                                sx={{ mr: 1 }}
                                                color="inherit"
                                            />
                                            Removing
                                        </>
                                    ) : (
                                        'Remove'
                                    )}
                                </Button>
                            ) : checkCouponExpired({ expirationDate: coupon?.expirationDate }) ? (
                                <Typography sx={ExpiredLabelStyle}>Expired</Typography>
                            ) : (
                                totalAmount >= coupon?.metaData?.minOrderValue && (
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        disabled={isCartLoading && coupon?.id === currSelectedId}
                                        sx={{ padding: '4px 12px' }}
                                        onClick={e => {
                                            applyCoupon(coupon?.id);
                                            setcurrSelectedId(coupon?.id);
                                        }}
                                    >
                                        {isCartLoading && coupon?.id === currSelectedId ? (
                                            <>
                                                <CircularProgress
                                                    size={15}
                                                    sx={{ mr: 1 }}
                                                    color="inherit"
                                                />
                                                Applying
                                            </>
                                        ) : (
                                            'Apply'
                                        )}
                                    </Button>
                                )
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Offer;
