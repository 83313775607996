import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import {
    container,
    LeftContainer,
    TimeBox,
    TimeMainBox,
    TimeStyle,
    TimeText,
    Title,
    ViewAllButton,
    DealEnd,
    DealEndBox,
    DividerStyle,
    MainContainer,
} from './dealStyle';
import Image from 'mui-image';
import { getDealImageForDay } from '../../../common/utils/dealsNavigationLabel';
import { useState, useEffect, useMemo } from 'react';
import Icon from '../../../component/icon';
import { ProductCard } from './DealsProdcutCard';
import useProductInfo from '../../../common/hooks/useProductInfo';
import styled from '@emotion/styled';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

export const ProductCatTabs = styled(Tabs)(() => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '.MuiTabs-scrollButtons': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
    },
    '.MuiTabs-scrollButtons:first-of-type': {
        left: 0,
        position: 'unset',
        width: '2rem',
        marginRight: '2rem',
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',
    },
    '.MuiTabs-scrollButtons:last-of-type': {
        right: 0,
        width: '2rem',
        marginRight: '2rem',
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',
    },
    '.MuiSvgIcon-root': {
        width: '2rem',
        height: '2rem',
        borderRadius: '50px',
        border: '1px solid #ECECEC',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ProductCatTab = styled(Tab)(({ theme }) => ({
    gap: '0.625rem',
    textTransform: 'none',
    marginRight: '1.5rem',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '0',
    color: 'var(--Black, #14141E)',
    '@media (max-width: 768px)': {
        marginRight: '1rem',
        gap: '0.5rem',
        fontSize: '14px',
        padding: '0',
    },
}));

export function Deals() {
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    const { data: productsData, refetch: getProducts } = useProductInfo({
        page: 1,
        limit: 10,
        tags: 'Hot Deals',
    });

    const calculateTimeLeft = () => {
        const now = new Date();
        const targetTime = new Date().setHours(24, 0, 0, 0);
        const difference = targetTime - now.getTime();

        let timeLeft = {
            hours: Math.floor(difference / (1000 * 60 * 60)),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (productsData?.prodcutsArray) {
            setProductData(productsData?.prodcutsArray);
        }
    }, [productsData]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Box sx={MainContainer}>
            <Box sx={container}>
                <Box sx={LeftContainer}>
                    <Typography sx={Title}>Deal of the day</Typography>
                    <Image
                        src={getDealImageForDay()}
                        alt=""
                        width={isMobile ? '12.8125rem' : '19rem'}
                        height={isMobile ? '2.8125rem' : '4.125rem'}
                    />
                    <Box sx={DealEndBox}>
                        <Divider
                            sx={{
                                ...DividerStyle,
                                background:
                                    'linear-gradient(to right, rgba(14, 50, 62, 0) 4%, #0E323E 100%)',
                            }}
                        />
                        <Typography sx={DealEnd}>Deals end in</Typography>
                        <Divider
                            sx={{
                                ...DividerStyle,
                                background:
                                    'linear-gradient(to left, rgba(14, 50, 62, 0) 4%, #0E323E 100%)',
                            }}
                        />
                    </Box>
                    <Box sx={TimeBox}>
                        <Box sx={TimeMainBox}>
                            <Typography sx={TimeStyle}>
                                {timeLeft.hours.toString().padStart(2, '0')}
                            </Typography>
                            <Typography sx={TimeText}>Hours</Typography>
                        </Box>
                        <Box sx={{ marginTop: '-20px' }}>
                            <Icon name="Time-Dot" />
                        </Box>
                        <Box sx={TimeMainBox}>
                            <Typography sx={TimeStyle}>
                                {timeLeft.minutes.toString().padStart(2, '0')}
                            </Typography>
                            <Typography sx={TimeText}>Minutes</Typography>
                        </Box>
                        <Box sx={{ marginTop: '-20px' }}>
                            <Icon name="Time-Dot" />
                        </Box>
                        <Box sx={TimeMainBox}>
                            <Typography sx={TimeStyle}>
                                {timeLeft.seconds.toString().padStart(2, '0')}
                            </Typography>
                            <Typography sx={TimeText}>Seconds</Typography>
                        </Box>
                    </Box>
                    {!isMobile && (
                        <Button
                            variant="contained"
                            sx={ViewAllButton}
                            onClick={e => navigate(`/products/deals`)}
                        >
                            View all <ArrowForwardIosIcon sx={{ fontSize: '1.25rem' }} />
                        </Button>
                    )}
                </Box>
                <ProductCatTabs
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ width: '100%', paddingRight: '10px' }}
                >
                    {productData.map((productInfo: any, index: any) => (
                        <ProductCatTab
                            key={index}
                            label={<ProductCard productData={productInfo} />}
                        />
                    ))}
                </ProductCatTabs>
                {isMobile && (
                    <Button
                        variant="contained"
                        sx={ViewAllButton}
                        onClick={e => navigate(`/products/deals`)}
                    >
                        View all{' '}
                        <ArrowForwardIosIcon sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }} />
                    </Button>
                )}
            </Box>
        </Box>
    );
}
