import { Link } from 'react-router-dom';
import { ConsultText, DesktopItemLabel, StyledNewText, StyleNewText } from './headerStyle';
import { Box, List, ListItem, ListItemText, Toolbar, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { DesktopNavItems } from '../landing/utils';
import FilterTag from '../product/FilterTag';

const MobileNavLinks = ({ toggleMenu }: any) => {
    const toggleSubMenu = (item: any) => {
        toggleMenu();
        if (item?.path) {
            window.location.href = item?.path ?? '#';
        }
    };

    return (
        <List>
            {DesktopNavItems.map((item: any, index: any) => (
                <>
                    <ListItem
                        key={index}
                        component={Link}
                        to={item.path || '#'}
                        onClick={() => toggleSubMenu(item)}
                        sx={{
                            width:
                                item?.label === 'Grooming' || item?.label === 'Consult a Vet'
                                    ? 'max-content'
                                    : '100%',
                        }}
                    >
                        <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{ color: '#fff' }}
                        />

                        {item?.label === 'Grooming' && (
                            <Box sx={StyleNewText}>
                                <AutoAwesomeIcon sx={{ color: '#FF7700', fontSize: '14px' }} />
                                <Typography sx={StyledNewText}>NEW</Typography>
                            </Box>
                        )}
                        {item?.label === 'Consult a Vet' && (
                            <>
                                <Typography
                                    sx={{
                                        ...DesktopItemLabel,
                                        ...ConsultText,
                                        border: '1px solid rgba(255, 119, 0, 0.20)',
                                        background: 'rgba(255, 119, 0, 0.30)',
                                        marginLeft: '8px',
                                        transition: 'border 0.30s linear, background 0.30s linear',
                                    }}
                                >
                                    Soon
                                </Typography>
                            </>
                        )}
                    </ListItem>
                </>
            ))}
            <Toolbar>
                <FilterTag title={'Deals & Offers'} />
            </Toolbar>
        </List>
    );
};

export default MobileNavLinks;
