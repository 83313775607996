import { Box } from '@mui/material';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { SliderBanner } from '../utils';

export function HeaderBanner() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };
    const isMobile = useCheckMobileScreen();

    return (
        <Box
            sx={{
                background: '#000',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                marginTop: isMobile ? '-120px' : '-134px',
            }}
        >
            <Slider {...settings}>
                {SliderBanner.map((slide: any) => (
                    <Box key={slide.id}>
                        <img
                            src={isMobile ? slide.imageMob : slide.image}
                            alt={slide.alt}
                            style={{
                                width: '100%',
                                height: '100%',
                                marginBottom: '-15px',
                                cursor: 'pointer',
                            }}
                            onClick={e => (window.location.href = '/products')}
                        />
                    </Box>
                ))}
            </Slider>
        </Box>
    );
}
