import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import { CURRENCY, CURRENCY_SIGN } from '../../../common/constants';
import MediaPreview from '../../../component/MediaPreview';
import {
    AddButton,
    OptionsStyle,
    originalPriceStyle,
    ProductTitle,
    SellingPrice,
} from './dealStyle';

export function ProductCard({ productData }: any) {
    const {
        pid,
        name,
        originalPrice,
        media,
        sellingPrice,
        discountValue,
        options,
        discountMetric,
    } = productData;
    const isMobile = useCheckMobileScreen();
    const theme = useTheme();
    return (
        <Card
            variant="outlined"
            sx={{
                border: 'none',
                height: isMobile ? '320px' : '360px',
                borderRadius: '1.12rem',
                width: isMobile ? '12.25rem' : '14.5rem',
            }}
            onClick={e => (window.location.href = `products/deals?product=${pid}`)}
        >
            <CardActionArea
                sx={{ height: '100%', borderRadius: '1rem', border: '1px solid #ececec' }}
            >
                {discountValue > 0 && (
                    <Box
                        p={1}
                        sx={{
                            background: theme.palette.primary.main,
                            position: 'absolute',
                            top: 10,
                            borderRadius: '0.5rem',
                            left: 10,
                        }}
                    >
                        <Typography variant="body2" fontSize={'10px'}>
                            {discountMetric === 'INR' ? CURRENCY : ' '} {discountValue}
                            {discountMetric === 'PERCENTAGE' ? '% ' : ' '}
                            Off
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: isMobile ? '144px' : '180px',
                            justifyContent: 'center',
                        }}
                    >
                        <MediaPreview media={media} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: isMobile ? '150px' : '100%',
                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'0.375rem'}
                            padding={'0 10px'}
                        >
                            <Typography sx={ProductTitle}>{name}</Typography>

                            <Stack flexDirection={'row'}>
                                <Typography sx={OptionsStyle}>
                                    {options.map((option: any, index: any) => {
                                        const name =
                                            option.values.length > 1
                                                ? `${option.name.toLowerCase()}s`
                                                : option.name.toLowerCase();

                                        let separator = '';
                                        if (index > 0 && index < options.length - 1) {
                                            separator = ', ';
                                        }
                                        if (index === options.length - 2) {
                                            separator = ' & ';
                                        }

                                        return (
                                            <>
                                                {option.values.length} {name}
                                                {separator}
                                            </>
                                        );
                                    })}
                                </Typography>
                            </Stack>
                        </Box>
                        <Stack>
                            <CardActions
                                sx={{
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography gutterBottom sx={SellingPrice}>
                                        {`${CURRENCY_SIGN}${
                                            sellingPrice
                                                ? sellingPrice
                                                : originalPrice -
                                                  (originalPrice * discountValue) / 100
                                        }`}
                                    </Typography>

                                    {discountValue > 0 && (
                                        <Typography gutterBottom sx={originalPriceStyle}>
                                            {`${CURRENCY_SIGN}${originalPrice}`}
                                        </Typography>
                                    )}
                                </Stack>
                            </CardActions>
                            <Stack sx={{ width: '100%', margin: '0!important' }}>
                                <Button size="large" fullWidth disableElevation sx={AddButton}>
                                    {'Add to cart'}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    );
}
