import { Box } from '@mui/material';
import { Grooming } from './Grooming';
import { useState } from 'react';
import { GroomingItems } from '../utils';
import { IndexContainer } from './groomingStyle';

export function MainService() {
    const [readMore, setReadMore] = useState(false);

    const handleReadMore = (index: any) => {
        setReadMore(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <Box sx={IndexContainer}>
            {GroomingItems.map((item: any, index: any) => (
                <Grooming
                    key={index}
                    handleReadMore={() => handleReadMore(index)}
                    readMore={readMore === index}
                    title={item.title}
                    tagTitle={item.tagTitle}
                    colors={item.color}
                    button={item.button}
                    image={item.image}
                    featureList={item.FeatureList}
                    index={index}
                    borderColor={item.borderColor}
                />
            ))}
        </Box>
    );
}
