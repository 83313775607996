export const Container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    maxWidth: '1440px',
    width: '100%',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        marginTop: '3rem',
        padding: 0,
    },
};

export const Title = {
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '2rem',
    color: '#14141E',
    textAlign: 'center',
    fontWeight: 700,
    letterSpacing: '1.28px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    },
};

export const MainContainer = {
    borderRadius: '40px',
    background: 'rgba(245, 239, 239, 0.80)',
    padding: '71px 100px 60px 0',
    marginTop: '76px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 1140px)': {
        paddingRight: '35px',
    },
    '@media (max-width: 768px)': {
        padding: '0',
        borderRadius: '24px',
    },
};

export const subTitle = {
    fontFamily: 'Riffic-Free-Bold',
    color: '#14141E',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '1.92px',
    textTransform: 'uppercase',
    paddingTop: '29px',
    paddingLeft: '105px',
    maxWidth: '400px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
        padding: 0,
        textAlign: 'center',
    },
};

export const TitleBox = {
    '@media (max-width: 768px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
};

export const TabsMainBox = {
    display: 'inline-flex',
    padding: '4px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '63px',
    border: '6px solid #FFF',
    background: '#F7F2F2',
    marginTop: '-110px',
    marginLeft: '110px',
    '@media (max-width: 768px)': {
        marginTop: '-40px',
        marginLeft: 0,
    },
};

export const TabStyle = {
    flexDirection: 'row',
    gap: '14px',
    padding: '8px 32px 8px 8px',
    borderRadius: '100px',
    minHeight: 'auto',
    '&.Mui-selected': {
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: 'unset',
    },
    '@media (max-width: 768px)': {
        gap: '10px',
    },
};

export const TabListStyle = {
    '& .MuiTabs-flexContainer': {
        gap: '10px',
        '@media (max-width: 768px)': {
            gap: '8px',
        },
    },
    '& .MuiTabs-indicator': {
        backgroundColor: 'unset',
        position: 'unset',
        transition: 'none',
    },
};

export const TabForText = {
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '10px',
    },
};

export const TabDogText = {
    fontFamily: 'Euclid-Circular-A-Bold',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    '@media (max-width: 768px)': {
        fontSize: '13.358px',
    },
};

export const RightContainer = {
    display: 'flex',
    gap: '34px',
    marginTop: '40px',
    width: '100%',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '20px',
        padding: '0 4px',
    },
};

export const ImageMainBox = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.5rem',
    '@media (max-width: 768px)': {
        gap: '0.5rem',
        padding: '4px',
    },
};

export const ImageBox = {
    flex: '1 0 46%',
    width: '135px',
    height: '280px',
    cursor: 'pointer',
    position: 'relative',
    '@media (max-width: 768px)': {
        height: '180px',
    },
};

export const ImageTextBox = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '21px 38px 0',
    zIndex: 2,
    position: 'absolute',
    '@media (max-width: 1240px)': {
        padding: '15px 15px 0',
    },
    '@media (max-width: 1100px)': {
        padding: '10px 8px 0',
    },
    '@media (max-width: 768px)': {
        padding: '12px 0 0',
    },
};

export const ImageBoxTitle = {
    color: 'var(--Black, #14141E)',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '14px',
    },
};

export const ImageBoxDescription = {
    color: '#4F4C4D',
    textAlign: 'center',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    paddingTop: '10px',
    '@media (max-width: 768px)': {
        fontSize: '10px',
        lineHeight: '14px',
        paddingTop: '3px',
    },
};

export const ImageDiscountBox = {
    display: 'inline-flex',
    padding: '5px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFD600',
    boxShadow: '-3px 3.51px 0px 0px #B89352',
    gap: '4px',
    marginTop: '12px',
    '@media (max-width: 768px)': {
        padding: '2.787px 5.573px',
        borderRadius: '4.459px',
        boxShadow: '-1.672px 1.956px 0px 0px #B89352',
        marginTop: 0,
    },
};

export const UptoDiscount = {
    color: '#14141E',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '10px',
    },
};

export const DiscountValue = {
    color: '#14141E',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '10px',
    },
};
