import { styled } from '@mui/material/styles';
import { AppBar, Box, Typography } from '@mui/material';

export const Headline = styled(Typography)`
    color: #fff;
    text-align: center;
    font-family: Cambon-Semi-Bold !important;
    font-size: 1.375rem;
    font-style: normal;
    line-height: normal;
`;

export const TooltipStyle = {
    justifyContent: 'space-between',
    maxWidth: '1440px',
    width: '100%',
    paddingRight: '4rem !important',
    paddingLeft: '4rem !important',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        justifyContent: 'space-between',
        paddingBlock: '0.875rem',
        width: '100%',
        paddingRight: '1.25rem !important',
        paddingLeft: '1.25rem !important',
    },
};

export const NewAppBar = styled(AppBar)`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &:before {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: -50px;
        height: 50px;
        left: 0px;
        width: 20px;
        border-top-left-radius: 1.25rem;
    }
    &:after {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: -50px;
        height: 50px;
        right: 0px;
        width: 20px;
        border-top-left-radius: 1.25rem;
        transform: rotate(0deg) scaleX(-1);
    }
`;

export const NavBarTop = styled(Box)`
    display: flex;
    width: 100%;
    padding: 0.625rem 3.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    background: var(--yellow-sec, #fddc2e);
    top: 0;
    position: relative;
    z-index: 100;
    height: 20px;
    @media (max-width: 768px) {
        padding: 0.625rem 1rem;
    }
`;

const Flex = styled(Box)`
    display: flex;
    flex-direction: row;
`;

export const HeaderContainer = {
    height: '134px',
    '@media (max-width: 768px)': {
        height: '120px'
    }
}

export const Item = styled(Flex)`
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 0.5rem;
    ${props => props.theme.breakpoints.down('md')} {
        justify-content: start;
        cursor: pointer;
    }
`;

export const DesktopItemLabel = {
    color: '#fff',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Euclid-Circular-A-Regular',
};

export const StyleLink = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
};

export const StyleNewText = {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    marginLeft: '4px',
    '@media (max-width: 768px)': {
        marginRight: '25px',
    }
};

export const ConsultText = {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: 'normal',
    padding: '3px 7px',
    borderRadius: '17px',
}

export const StyledNewText = {
    color: '#FF7700',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: 'normal'
}

export const CartAndAccountBox = {
    display: 'flex',
    gap: '28px',
    alignItems: 'end',
    color: '#FFF',
    '@media (max-width: 768px)': {
        gap: '22px',
    }
};

export const AccountBox = {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'end',
    color: '#FFF',
    cursor: 'pointer',
};

export const StyledMenu = {
    '& .MuiPaper-root': {
        borderRadius: 2,
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    },
};

export const AdvertisingContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fddc2e',
    height: '40px',
}

export const AdvertisingMain = {
    display: 'flex',
    paddingBlock: '0.625rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.375rem',
    maxWidth: '1440px',
    width: '100%',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
}

export const AdvertisingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.375rem',
    width: '100%',
}

export const AdvertisingText = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
}

export const AdvertisingCloseBox = {
    borderRadius: '40px',
    background: '#FFF',
    padding: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
}
