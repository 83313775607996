import { Box, Tab, Typography } from '@mui/material';
import {
    Container,
    ImageBox,
    ImageMainBox,
    MainContainer,
    RightContainer,
    subTitle,
    TabDogText,
    TabForText,
    TabListStyle,
    TabsMainBox,
    TabStyle,
    Title,
    TitleBox,
    ImageBoxDescription,
    ImageBoxTitle,
    ImageDiscountBox,
    DiscountValue,
    UptoDiscount,
    ImageTextBox,
} from './bestSellerStyle';
import Image from '../../../component/image/image';
import { TabContext, TabList } from '@mui/lab';
import Dog from '../../../assets/images/forDog.webp';
import Cat from '../../../assets/images/forCat.webp';
import Icon from '../../../component/icon';
import { useState } from 'react';
import { BestSellerContent, BestSellerTabs } from '../utils';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';

export function BestSeller() {
    const isMobile = useCheckMobileScreen();
    const [selectedTab, setSelectedTab] = useState('For-Dog');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={Container}>
            <Typography sx={Title}>Shop Bestsellers</Typography>
            <Box sx={MainContainer}>
                <Box sx={TabsMainBox}>
                    <TabContext value={selectedTab}>
                        <TabList
                            sx={TabListStyle}
                            onChange={handleTabChange}
                            aria-label="Bestseller Tabs"
                        >
                            {BestSellerTabs.map((tab, i) => (
                                <Tab
                                    key={i}
                                    label={
                                        <Box sx={{ textAlign: 'start' }}>
                                            <Typography
                                                sx={{
                                                    ...TabForText,
                                                    color:
                                                        selectedTab === tab.value
                                                            ? '#616264'
                                                            : '#666',
                                                }}
                                            >
                                                For
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    ...TabDogText,
                                                    color:
                                                        selectedTab === tab.value
                                                            ? '#231F20'
                                                            : '#666',
                                                }}
                                            >
                                                {tab.label}
                                            </Typography>
                                        </Box>
                                    }
                                    icon={
                                        <Icon
                                            name={
                                                selectedTab === tab.value
                                                    ? tab.selectedIcon
                                                    : tab.icon
                                            }
                                        />
                                    }
                                    value={tab.value}
                                    sx={TabStyle}
                                />
                            ))}
                        </TabList>
                    </TabContext>
                </Box>
                <Box sx={RightContainer}>
                    <Box sx={TitleBox}>
                        <Typography sx={subTitle}>
                            {selectedTab === 'For-Dog' ? 'The Woof Shop' : 'The Meow Shop'}
                        </Typography>
                        <Image
                            src={selectedTab === 'For-Dog' ? Dog : Cat}
                            alt=""
                            width={isMobile ? '300px' : '450px'}
                            height={isMobile ? '200px' : '327px'}
                            style={{
                                objectFit: 'contain',
                                marginTop: isMobile ? '0.75rem' : '3rem',
                            }}
                        />
                    </Box>
                    <Box sx={ImageMainBox}>
                        {BestSellerContent[selectedTab].map((item: any, i: any) => (
                            <Box
                                sx={ImageBox}
                                key={i}
                                onClick={() => (window.location.href = item.link)}
                            >
                                <Box sx={ImageTextBox}>
                                    <Typography sx={ImageBoxTitle}>{item.title}</Typography>
                                    <Typography sx={ImageBoxDescription}>
                                        {item.description}
                                    </Typography>
                                    <Box sx={ImageDiscountBox}>
                                        <Typography sx={UptoDiscount}>Upto </Typography>
                                        <Typography sx={DiscountValue}>
                                            {item.discountTitle}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Image
                                    src={item.image}
                                    alt=""
                                    width={'100%'}
                                    height={'100%'}
                                    style={{ borderRadius: '16px' }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
