import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { filtersStyle } from './productStyle';
import Icon from '../../component/icon';
const FilterTag = ({ removeFilter, title, filterType }: any) => {
    const currentRoute = window.location.pathname;

    return (
        <Box
            onClick={e => !removeFilter && (window.location.href = '/products/deals')}
            sx={
                removeFilter
                    ? filtersStyle
                    : {
                          display: 'flex',
                          padding: '9px 18px 9px 12px',
                          alignItems: 'center',
                          gap: '8px',
                          borderRadius: '62px',
                          height: '40px',
                          background: '#fff',
                          color: '#14141E',
                          cursor: 'pointer',
                      }
            }
        >
            {!removeFilter && <Icon name="hot-deals-yellow" />}

            <Typography sx={{ fontWeight: '500' }}>{title}</Typography>
            {removeFilter && <CloseIcon onClick={e => removeFilter(filterType || '')} />}
        </Box>
    );
};

export default FilterTag;
