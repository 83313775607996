export const container = {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    gap: "2.625rem",
    width: '100%',
    maxWidth: '1440px',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        gap: '1.375rem',
        padding: 0,
    }
}

export const Title = {
    color: '#14141E',
    textAlign: 'center',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '1.28px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    }
}

export const LabelBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    '@media (max-width: 768px)': {
        gap: '4px',
    }
}

export const CategoryTitle = {
    color: 'var(--Black, #14141E)',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
    }
}

export const CategorySub = {
    fontSize: '18px',
    fontWeight: '600',
    color: '#4F4C4D',
    fontFamily: 'Euclid-Circular-A-Regular',
    lineHeight: '20px',
    '@media (max-width: 768px)': {
        fontSize: '14px',
    }
}
