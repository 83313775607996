import { Box, Typography } from '@mui/material';
import { Container, ImageBox, SubTitle, Title, TitleBox } from './feedStyle';
import { useMutation } from '@tanstack/react-query';
import { getPosts } from '../../../service/api/posts';
import { useEffect, useState } from 'react';
import Icon from '../../../component/icon';
import VideoPlayer from '../../../component/VideoPlayer';

const videoPlayerStyle = {
    minWidth: '247px',
    borderRadius: '12px',
    overflow: 'hidden',
};

export function Feeds() {
    const [postsData, setPostsData] = useState({
        data: [],
        pagination: {
            page: 1,
            limit: 100,
            total: 0,
        },
    });

    const getPostsMut = useMutation({
        mutationFn: (data: any) => getPosts(data),
        onSuccess: (res: any) => {
            setPostsData(res.data);
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET Posts', error);
        },
    });

    useEffect(() => {
        getPostsMut.mutate({
            page: 1,
            limit: 10,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={Container}>
            <Box sx={TitleBox}>
                <Typography sx={Title}>Our Pet care stories</Typography>
                <Typography sx={SubTitle}>Share your love & feedbacks on social media</Typography>
            </Box>
            <Box sx={ImageBox}>
                {postsData.data.map((post: any) =>
                    post?.media?.map((media: any) => (
                        <Box
                            key={media.id}
                            style={{
                                position: 'relative',
                                height: '100%',
                                display: 'inline-block',
                            }}
                        >
                            {media.type === 'image' ? (
                                <img
                                    src={media.url}
                                    alt=""
                                    style={{
                                        maxWidth: '278px',
                                        borderRadius: '12px',
                                    }}
                                />
                            ) : (
                                <VideoPlayer
                                    cssStyle={videoPlayerStyle}
                                    url={media.url}
                                    width="286px"
                                    height="500px"
                                />
                            )}
                            {media.type === 'image' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '18px',
                                        right: '18px',
                                        zIndex: 200,
                                        height: '22px',
                                    }}
                                >
                                    <Icon name={'logo-white'} />
                                </Box>
                            )}
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
}
