import { Box, Typography } from '@mui/material';
import {
    ContainerStyle,
    ProductContainerStyle,
    SubTitleStyle,
    TitleStyle,
    ItemTitle,
    ItemIconBox,
    ItemTitleBox,
    SubTitle,
    ShopNowButton,
} from './PersonalizedUniqueStyle';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import Image from '../../../component/image/image';
import { personalized } from '../utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

export function PersonalizedUniqueSection() {
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    return (
        <Box sx={ContainerStyle}>
            <Typography sx={TitleStyle}>Personalized & Unique</Typography>
            <Typography sx={SubTitleStyle}>Create unique product and give person touch.</Typography>

            <Box sx={ProductContainerStyle}>
                {personalized.map((item: any, i: any) => (
                    <Box key={i}>
                        <Box sx={ItemTitleBox}>
                            <Typography sx={SubTitle}>{item.subTitle}</Typography>
                            <Typography sx={ItemTitle}>{item.title}</Typography>
                            <Box sx={ItemIconBox}>
                                <Typography sx={ShopNowButton} onClick={e => navigate(item.link)}>
                                    Shop Now
                                </Typography>
                                <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                marginTop: isMobile ? '-190px' : '-238px',
                            }}
                        >
                            <Image
                                src={item.image}
                                alt=""
                                width={isMobile ? '100%' : '485.51px'}
                                height={isMobile ? 'auto' : '581.7px'}
                                style={{
                                    borderRadius: '20px',
                                    objectFit: 'contain',
                                }}
                            />
                            {item.imageGif && (
                                <Image
                                    src={item.imageGif}
                                    alt=""
                                    width={isMobile ? '100%' : '485.51px'}
                                    height={isMobile ? 'auto' : '581.7px'}
                                    style={{
                                        position: 'absolute',
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
