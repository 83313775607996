import { useState, useEffect } from 'react';
import Icons from '../../component/icon';
import {
    AccountBox,
    CartAndAccountBox,
    DesktopItemLabel,
    HeaderContainer,
    Item,
    NewAppBar,
    StyleLink,
    TooltipStyle,
} from './headerStyle';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Badge, InputBase, Typography } from '@mui/material';
import { Box, Collapse, IconButton, Stack, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CartView from '../cart/CartView';
import { getCartsAction } from '../../service/action/cart/cartActions';
import { selectCartItems, selectCartDrawer } from '../../service/selectors/cartSelectors';
import { appActions } from '../../service/action';
import { Icon } from '@iconify/react';
import LoginSignup from '../auth/loginSignup';
import PersonIcon from '@mui/icons-material/Person';
import { getDealsLabel } from '../../common/utils/dealsNavigationLabel';
import { getUserAction } from '../../service/action/userActions';
import { userSelector } from '../../service/selectors/userSelector';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import FilterTag from '../product/FilterTag';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBox from './SearchBox';
import DesktopNavLinks from './desktopNavLinks';
import MobileNavLinks from './MobileNavLinks';
// import Advertising from './advertising';

const Header = ({
    variant = 'static',
    enableSearch = false,
}: {
    variant?: 'fluid' | 'static';
    enableSearch?: Boolean;
}) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useCheckMobileScreen();
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);

    const cartItems = useSelector(selectCartItems);
    const cartDrawer = useSelector(selectCartDrawer);
    const [openModal, setOpenModal] = useState(false);

    const location = useLocation();
    const currentRoute = location.pathname;
    const { orderId } = useParams();

    const token = localStorage.getItem('accessToken');
    const [authToken, setAuthToken] = useState(token);
    const userData = useSelector(userSelector);
    const [scrolled, setScrolled] = useState(false);

    let title = 'Our Products';
    if (currentRoute === '/products') {
        title = 'Our Products';
    }
    if (currentRoute === '/products/deals') {
        title = getDealsLabel();
    }
    if (currentRoute === '/service') {
        title = 'Book a Service';
    }
    if (currentRoute === '/order') {
        title = 'Checkout';
    }
    if (orderId) {
        title = 'Confirmation';
    }
    if (currentRoute === '/privacy-policy') {
        title = 'Privacy Policy';
    }
    if (currentRoute === '/terms-conditions') {
        title = 'Terms & Conditions';
    }
    if (currentRoute === '/profile') {
        title = 'My Account';
    }
    if (currentRoute === '/profile/rewards') {
        title = 'My Rewards';
    }
    if (currentRoute === '/profile/coupons') {
        title = 'Coupons';
    }
    if (currentRoute === '/profile/orders') {
        title = 'My Orders';
    }

    const toggleCartDrawer = (anchor: any, open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        dispatch({
            type: `${appActions.SET_CART_DRAWER}_SUCCESS`,
            payload: { ...cartDrawer, [anchor]: open },
        });
    };

    const handleAddToCart = () => {
        toggleCartDrawer('right', true)({});
    };

    const toggleSearch = () => {
        setIsSearchExpanded(!isSearchExpanded);
    };

    const toggleMenu = () => setIsExpanded(prevState => !prevState);

    const openLoginModal = () => {
        if (isMobile) {
            toggleMenu();
        }
        setOpenModal(true);
    };

    useEffect(() => {
        window.scroll(0, 10);
    }, [location]);

    useEffect(() => {
        dispatch(getCartsAction({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const user = Object.keys(userData).length === 0;

        if (user && token) {
            dispatch(getUserAction({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    const redirectTo = (url: any) => {
        window.location.href = url;
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box sx={HeaderContainer}>
            <NewAppBar
                position={!isMobile ? (variant === 'fluid' ? 'relative' : undefined) : undefined}
                elevation={0}
                sx={{
                    backgroundColor:
                        currentRoute === '/'
                            ? scrolled || isExpanded === true
                                ? '#14141E'
                                : 'transparent'
                            : '#14141e',
                    borderBottom: `1px solid ${
                        currentRoute === '/'
                            ? scrolled || isExpanded === true
                                ? '#14141E'
                                : '#00000029'
                            : '#2E2E40'
                    }`,
                    transition: isMobile
                        ? 'background-color 1s linear, border-bottom 1s linear'
                        : 'background-color 0.10s linear, border-bottom 0.10s linear',
                }}
            >
                {/* {!isMobile && <Advertising />} */}

                <Toolbar sx={{ ...TooltipStyle, paddingBlock: '12px 9px', gap: '2.5rem' }}>
                    {!isMobile ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '2.5rem',
                                width: '100%',
                            }}
                        >
                            <Link to={'/'} style={StyleLink}>
                                <Item>
                                    <Icons name={'header-logo-yellow'} />
                                </Item>
                            </Link>

                            <SearchBox />
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                width={'100%'}
                                marginBottom={'1rem'}
                            >
                                <Box sx={{ display: 'flex', gap: '18px', alignItems: 'center' }}>
                                    <IconButton
                                        onClick={toggleMenu}
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ padding: 0, margin: 0 }}
                                    >
                                        {isMobileMenuExpanded ? (
                                            <Icons name={'close-icon'} />
                                        ) : (
                                            <MenuIcon sx={{ color: '#fff', fontSize: '2rem' }} />
                                        )}
                                    </IconButton>
                                    <Link to="/" style={{ height: '38px' }}>
                                        <Icons name={'header-logo-yellow'} />
                                    </Link>
                                </Box>
                                <Stack direction="row" alignItems="center" spacing={0} gap={'12px'}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        sx={{ display: enableSearch ? 'block' : 'none' }}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={toggleSearch}
                                    >
                                        <Icons name={'search-mob'} />
                                    </IconButton>
                                    <Box onClick={e => redirectTo('/support')}>
                                        <Icons name={'Call'} />
                                    </Box>
                                    {authToken ? (
                                        <Box onClick={e => redirectTo('/profile')}>
                                            <Icons name={'PersonIcon'} />
                                        </Box>
                                    ) : (
                                        <Box onClick={openLoginModal}>
                                            <Icons name={'PersonIcon'} />
                                        </Box>
                                    )}
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ padding: 0, margin: 0 }}
                                    >
                                        <Badge
                                            sx={{
                                                '.MuiBadge-badge': {
                                                    backgroundColor: '#FF4E16',
                                                },
                                            }}
                                            badgeContent={
                                                cartItems && cartItems?.items?.length > 0 ? (
                                                    <span
                                                        style={{
                                                            color: '#FFF',
                                                            fontFamily: 'Euclid-Circular-A-Medium',
                                                            fontSize: '1rem',
                                                            fontStyle: 'normal',
                                                            lineHeight: 'normal',
                                                        }}
                                                    >
                                                        {cartItems ? cartItems?.items?.length : 0}
                                                    </span>
                                                ) : null
                                            }
                                            color="secondary"
                                            onClick={handleAddToCart}
                                        >
                                            <Icons name={'Cart'} />
                                        </Badge>
                                    </IconButton>
                                </Stack>
                            </Box>
                            <Box>
                                <SearchBox />
                            </Box>
                        </Box>
                    )}

                    {!isMobile && (
                        <>
                            <Box sx={CartAndAccountBox}>
                                <Box sx={AccountBox}>
                                    <Link to="/support" style={StyleLink}>
                                        <Item>
                                            <Icons name={'Call'} />
                                            <Typography sx={DesktopItemLabel}>Support</Typography>
                                        </Item>
                                    </Link>
                                </Box>
                                <Box>
                                    {authToken ? (
                                        <Link
                                            to={isMobile ? '/profile' : '/profile/rewards'}
                                            style={StyleLink}
                                        >
                                            <Item>
                                                <Icons name={'PersonIcon'} />
                                                <Typography sx={DesktopItemLabel}>
                                                    Account
                                                </Typography>
                                            </Item>
                                        </Link>
                                    ) : (
                                        <Item>
                                            <Badge
                                                sx={{
                                                    '.MuiBadge-badge': {
                                                        backgroundColor: '#FF4E16',
                                                    },
                                                }}
                                                onClick={openLoginModal}
                                                color="secondary"
                                            >
                                                <Icons name={'PersonIcon'} />
                                            </Badge>
                                            <Typography
                                                sx={DesktopItemLabel}
                                                onClick={openLoginModal}
                                            >
                                                {`Login/Signup`}
                                            </Typography>
                                        </Item>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        alignItems: 'end',
                                        color: '#FFF',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleAddToCart}
                                >
                                    <Badge
                                        sx={{
                                            '.MuiBadge-badge': {
                                                backgroundColor: '#FF4E16',
                                            },
                                        }}
                                        badgeContent={
                                            cartItems && cartItems?.items?.length > 0 ? (
                                                <span
                                                    style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Euclid-Circular-A-Medium',
                                                        fontSize: '1rem',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    {cartItems ? cartItems?.items?.length : 0}
                                                </span>
                                            ) : null
                                        }
                                        color="secondary"
                                    >
                                        <Icons name={'Cart'} />
                                    </Badge>
                                    <Typography sx={DesktopItemLabel}>Cart</Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </Toolbar>
                {!isMobile && (
                    <Toolbar sx={{ ...TooltipStyle, paddingBlock: '9px 12px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                            <DesktopNavLinks />
                        </Box>
                        <FilterTag title={'Deals & Offers'} />
                    </Toolbar>
                )}

                {isMobile && (
                    <Collapse in={isSearchExpanded}>
                        <Toolbar>
                            <InputBase
                                placeholder="Search..."
                                sx={{
                                    flexGrow: 1,
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    padding: '0.5rem',
                                }}
                            />

                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="search"
                                onClick={toggleSearch}
                            >
                                <Icons name={'close-icon'} />
                            </IconButton>
                        </Toolbar>
                    </Collapse>
                )}
                {isMobile && (
                    <Collapse in={isExpanded && isMobile} sx={{ width: '100%' }}>
                        <Toolbar sx={{ display: 'unset' }}>
                            <MobileNavLinks toggleMenu={toggleMenu} />
                        </Toolbar>
                    </Collapse>
                )}
            </NewAppBar>
            {cartDrawer.right && (
                <CartView cartDrawer={cartDrawer} toggleDrawer={toggleCartDrawer} />
            )}
            {openModal && (
                <LoginSignup open={openModal} setOpen={setOpenModal} setAuthToken={setAuthToken} />
            )}
        </Box>
    );
};

export default Header;
