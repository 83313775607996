import { Box, Button, Typography } from '@mui/material';
import {
    ButtonStyle,
    ButtonText,
    Container,
    GroomingBox,
    GroomingText,
    ListBox,
    ListMainContainer,
    Span,
    ListTitle,
    ListSubTitle,
    Title,
    ListTextBox,
    MainContainer,
    ViewButton,
    ContainerBox,
} from './groomingStyle';
import Image from '../../../component/image/image';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Icon from '../../../component/icon';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

export function Grooming({
    handleReadMore,
    readMore,
    title,
    colors,
    button,
    image,
    featureList,
    index,
    tagTitle,
    borderColor,
}: any) {
    const isMobile = useCheckMobileScreen();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop >= 3450);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box
            sx={{
                ...ContainerBox,
                maxWidth: index === 0 ? (isScrolled ? '1440px' : '100%') : '1440px',
                marginTop: isMobile ? '' : index === 0 ? (isScrolled ? '80px' : 0) : '',
                paddingRight: index === 0 ? (isScrolled ? '4rem' : 0) : '4rem',
                paddingLeft: index === 0 ? (isScrolled ? '4rem' : 0) : '4rem',
                paddingTop: isMobile
                    ? ''
                    : `calc(var(--index, ${index}) * var(--card-top-offset, 80px))`,
                transition:
                    'padding-right 0.5s linear, padding-left 0.5s linear,max-width 0.5s linear, margin-top 0.5s linear',
                '@media (max-width: 1440px)': {
                    maxWidth: isScrolled ? '1240px' : '100%',
                },
            }}
        >
            <Box
                sx={{
                    ...Container,
                    background: colors,
                    borderRadius: isScrolled ? '40px' : 0,
                    transition: 'border-radius 0.5s linear',
                }}
            >
                <Box sx={MainContainer}>
                    <Box sx={GroomingBox}>
                        <Icon name="stars" />
                        <Typography sx={GroomingText}>{tagTitle}</Typography>
                    </Box>
                    <Typography sx={Title}>{title}</Typography>
                    <Box sx={{ marginBottom: readMore || index === 1 ? '' : '33px' }}>
                        <Button sx={ButtonStyle}>
                            <Typography sx={ButtonText}>
                                {button} {index === 0 && <span style={Span}>- now</span>}
                            </Typography>
                            {index === 0 && (
                                <ArrowForwardIosIcon sx={{ fontSize: '1rem', color: '#231F20' }} />
                            )}
                        </Button>
                    </Box>

                    {(!isMobile || !readMore) && (
                        <Image
                            src={image}
                            alt=""
                            width={isMobile ? '300px' : index === 0 ? '358.14px' : '100%'}
                            height={isMobile ? '227px' : index === 0 ? '266.952px' : '100%'}
                            style={{
                                objectFit: isMobile ? 'contain' : '',
                                position: isMobile ? '' : 'absolute',
                                bottom: 0,
                                maxWidth: '447px',
                                maxHeight: '357px',
                                marginLeft: isMobile ? '' : index === 1 ? '52px' : '',
                            }}
                        />
                    )}
                </Box>
                {(readMore || !isMobile) && (
                    <>
                        <Box sx={ListMainContainer}>
                            {featureList.map((feature: any, index: any) => (
                                <Box key={index} sx={ListBox}>
                                    <Box
                                        sx={{
                                            width: isMobile ? '3.5rem' : '1.5rem',
                                            height: '1.5rem',
                                        }}
                                    >
                                        <Icon name={feature.icon} />
                                    </Box>
                                    <Box sx={ListTextBox}>
                                        <Typography sx={ListTitle}>{feature.title}</Typography>
                                        <Typography sx={ListSubTitle}>
                                            {feature.subTitle}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>

                        {isMobile && (
                            <Image
                                src={image}
                                alt=""
                                width={'358.14px'}
                                height={isMobile ? '227px' : '266.95px'}
                                style={{ objectFit: isMobile ? 'contain' : '' }}
                            />
                        )}
                    </>
                )}
            </Box>
            {isMobile && (
                <Button
                    onClick={handleReadMore}
                    sx={{
                        ...ViewButton,
                        background: `${colors} !important`,
                        borderTop: `1px solid ${borderColor}`,
                    }}
                >
                    {readMore ? (
                        <KeyboardArrowUpIcon sx={{ marginTop: '3px' }} />
                    ) : (
                        <KeyboardArrowDownIcon sx={{ marginTop: '3px' }} />
                    )}
                    {readMore ? 'Hide ' : 'View '} Details
                </Button>
            )}
        </Box>
    );
}
