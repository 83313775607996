import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MainContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #14141E;
`

export const FooterContainer = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1440px',
    gap: '76px',
    padding: '80px 4rem 0',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 768px)': {
        gap: '44px',
        padding: '40px 20px 0'
    }
}

export const IconGrid = {
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
        padding: '0'
    }
}

export const Title = {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    fontFamily: 'Euclid-Circular-A-Regular',
    color: '#FFF',
    letterSpacing: '-0.28px',
    margin: '24px 0 40px',
    '@media (max-width: 768px)': {
        margin: '22px 0 24px',
        lineHeight: '22px',
    }
}

export const FollowBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 768px)': {
        gap: '20px',
        flexDirection: 'row',
        paddingBottom: '4px',
    }
}

export const FollowStyle = {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '24px',
    fontFamily: 'Euclid-Circular-A-Regular',
    color: '#FFF',
    letterSpacing: '0.32px',
}

export const FollowLinkBox = {
    display: 'flex',
    gap: '19px',
    '@media (max-width: 768px)': {
        gap: '1rem',
    }
}

export const MenuGrid = {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    '@media (max-width: 768px)': {
        gap: '1.5rem',
        paddingTop: '40px !important'
    }
}

export const MenuTitle = {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '24px',
    fontFamily: 'Euclid-Circular-A-Regular',
    color: '#FFF',
    letterSpacing: '0.36px',
}

export const MenuItemsBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    '@media (max-width: 768px)': {
        gap: '17px',
    }
}

export const MenuItems = {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Euclid-Circular-A-Regular',
    color: 'rgba(255, 255, 255, 0.80)',
    letterSpacing: '-0.28px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    textDecoration: 'unset',
    width: 'fit-content',
}

export const HelpGrid = {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    '@media (max-width: 768px)': {
        gap: '1.5rem',
        paddingTop: '44px !important',
    }
}

export const NeedHelpText = {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: 'Euclid-Circular-A-Regular',
    color: '#fff',
    letterSpacing: '0.36px',
    lineHeight: '24px',
    fontStyle: 'normal',
    '@media (max-width: 768px)': {
        letterSpacing: '0.32px',
    }
}

export const SupportTitle = {
    color: '#FFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '-0.32px',
}

export const Support = {
    color: 'var(--yellow-pri, #FFD600)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.48px',
    marginTop: '2px',
    textDecorationLine: 'unset',
}

export const SupportTime = {
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
    padding: '3px 10px',
    borderRadius: '16px',
    fontFamily: "Euclid-Circular-A-Regular",
    marginLeft: "7px",
    fontSize: "14px",
    color: '#fff',
}

export const DividerStyle = {
    width: '100%',
    background: 'rgba(128, 132, 144, 0.20)',
    margin: '24px 0',
    '@media (max-width: 768px)': {
        margin: '20px 0'
    }
}

export const CopyRightText={
    color: 'rgba(255, 255, 255, 0.80)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.28px',
}

export const CopyRight={
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: '1px solid rgba(128, 132, 144, 0.20)',
    padding: '24px 0',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '18px',
        padding: '24px 0 40px',
    }
}

export const MoreDetails = {
    color: 'rgba(255, 255, 255, 0.80)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.28px',
    textDecorationLine: 'underline',
}
