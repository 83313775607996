export const MainContainer = {
    display: 'flex',
    background: '#FDF7F4',
    padding: '63px 0',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        padding: '80px 20px 70px',
    },
};

export const CurveStyle = {
    backgroundColor: '#ffffff',
    height: '64px',
    width: '100%',
    position: 'absolute',
    '@media (max-width: 768px)': {
        height: '40px',
    },
};

export const Container = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '80px',
    maxWidth: '1440px',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px'
    },
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '40px',
        width: '100%',
        padding: 0,
    },
};

export const TitleBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '36px 0 60px',
    marginBottom: '60px',
    borderBottom: '1px solid #ECECEC',
    '@media (max-width: 768px)': {
        padding: '28px 0 40px',
        marginBottom: '40px',
        gap: '0.75rem',
    },
};

export const Title = {
    color: '#14141E',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '58px',
    letterSpacing: '1.76px',
    width: '400px',
    '@media (max-width: 768px)': {
        fontSize: '2.25rem',
        lineHeight: '46px',
        letterSpacing: '1.44px',
        width: '100%',
    },
};

export const SubTitle = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
    maxWidth: '365px',
    letterSpacing: '-0.18px',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        letterSpacing: '-0.16px',
        width: '100%',
        lineHeight: '1.5rem',
    },
};

export const RatingCount = {
    color: '#1B222B',
    fontFamily: 'Euclid-Circular-A-Bold',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: 'normal',
    letterSpacing: '1.92px',
    '@media (max-width: 768px)': {
        fontSize: '2.5rem',
        letterSpacing: '1.6px',
    },
};

export const RatingBox = {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
};

export const RatingIcons = {
    display: 'flex',
    gap: '8px',
};

export const ReviewsTextBox = {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    marginTop: '14px',
};

export const ReviewsText = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '1.125rem',
    },
};

export const GoogleText = {
    color: '#4F4C4D',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    opacity: '0.65',
};

export const RightBox = {
    display: 'flex',
    flexWrap: 'wrap',
    height: '850px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    gap: '20px',
};

export const GoogleCommentMainContainer = {
    '@media (max-width: 768px)': {
        width: '100%',
    },
};

export const BlurSection = {
    width: '100%',
    height: '257.445px',
    background: 'linear-gradient(180deg, #FDF7F4 0%, rgba(254, 249, 247, 0.00) 100%)',
    position: 'relative',
    zIndex: 3,
    marginTop: '-3px',
};

export const GoogleCommentContainer = {
    display: 'flex',
    gap: '20px',
    height: '1000px',
    overflow: 'hidden',
    width: '100%',
    marginTop: '-250px',
    '@media (max-width: 768px)': {
        gap: '16px',
        flexDirection: 'column',
        height: 'unset',
        marginTop: 0,
    },
};

export const ReviewsBox = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'unset',
    height: '1000px',
    scrollbarWidth: 'none',
    transition: 'overflow 0.2s ease',
    animation: 'scrollTop 40s linear infinite',
    width: '50%',
    '@keyframes scrollTop': {
        '0%': { transform: 'translateY(0%)' },
        '100%': { transform: 'translateY(-100%)' },
    },
    '@media (max-width: 768px)': {
        gap: '20px',
        flexDirection: 'row',
        height: 'unset',
        width: '100%',
        animation: 'unset',
        overflowY: 'unset',
        overflowX: 'scroll',
    },
};

export const GoogleCommentBox = {
    borderRadius: '22px',
    background: '#FFF',
    display: 'flex',
    padding: '22px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        width: '100%',
        minWidth: '300px',
    },
};

export const GoogleComment = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '4',
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: '#1B222B',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
};

export const GoogleCommentIconBox = {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
};

export const GoogleCustomerName = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '-0.36px',
};

export const GoogleCustomerType = {
    color: '#616264',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.15px',
};
