import { Box } from '@mui/material';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Icon from './icon';

type Props = {
    url: string;
    width: number | string;
    height: number | string;
    playing?: boolean;
    loop?: boolean;
    cssStyle: object;
};

const VideoPlayer = ({ url, width, height, playing = true, loop = true, cssStyle }: Props) => {
    const [isMuted, setIsMuted] = useState(true);

    return (
        <>
            <ReactPlayer
                style={cssStyle}
                url={url}
                width={width}
                height={height}
                playing={playing}
                muted={isMuted}
                loop={loop}
                fallback={<div>Video not supported</div>}
            />
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '18px',
                        right: '18px',
                        zIndex: 200,
                        height: '22px',
                    }}
                >
                    <Icon name={'logo-white'} />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '18px',
                        left: '18px',
                        zIndex: 200,
                        height: '22px',
                    }}
                    onClick={() => setIsMuted(!isMuted)}
                >
                    {isMuted ? (
                        <VolumeOffIcon style={{ color: 'white' }} />
                    ) : (
                        <VolumeDownIcon style={{ color: 'white' }} />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default VideoPlayer;
