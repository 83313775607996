import { ReactComponent as Logo } from '../../assets/logos/logo.svg';
import { ReactComponent as LogoWhite } from '../../assets/logos/logo-white.svg';
import { ReactComponent as LogoYellow } from '../../assets/logos/logo-yellow.svg';
import { ReactComponent as LogoYellowHeader } from '../../assets/logos/header-logo-yellow.svg';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import {
    Affordable,
    ArrowDown,
    ArrowDownSmall,
    ArrowUpSmall,
    BlackCat,
    BlackDog,
    Calender,
    Call,
    Cart,
    Charity,
    ClearFilter,
    Close,
    COD,
    ConfirmationGraphic,
    Consultations,
    Coupons,
    CustomerSupport,
    DeliveryTime,
    DesktopMap,
    DesktopMedia,
    DesktopScissorsFill,
    DesktopShoppingBag,
    Discount,
    DiscountCode,
    EndStack,
    Exchange,
    Facebook,
    FastDelivery,
    ForCat,
    ForDog,
    Google,
    Hamburger,
    Home,
    HomeAddress,
    HomeService,
    HotDeals,
    HotDealsYellow,
    Instagram,
    LoginFrame,
    LogoIcon,
    Mail,
    Map,
    MapMob,
    Media,
    MediaMob,
    MoneyStack,
    MyOrders,
    PawCare,
    Personalized,
    PersonIcon,
    PickDrop,
    POD,
    Pricing,
    Profile,
    Pupdates,
    QRCode,
    Rating,
    Recommendations,
    Return,
    Rewards,
    SafeSecure,
    Scheduling,
    ScissorsFill,
    ScissorsFillMob,
    SearchMob,
    Secure,
    Service,
    ShoppingBagFill,
    ShoppingBagFillMob,
    SizeGuide,
    Smile,
    Stars,
    Stylist,
    Support,
    TimeDot,
    TrackShipment,
    Twitter,
    User,
    Walks,
    Wallet,
    WhatsApp,
    YouTube,
} from '../../assets/icons';
import { JSX } from 'react';

interface IconPropsType {
    name: string;
}

const IconMap: Record<string, JSX.Element> = {
    'map': <Map />,
    'map-mob': <MapMob />,
    'media': <Media />,
    'media-mob': <MediaMob />,
    'scissors-fill': <ScissorsFill />,
    'scissors-fill-mob': <ScissorsFillMob />,
    'bag-fill': <ShoppingBagFill />,
    'bag-fill-mob': <ShoppingBagFillMob />,
    'discount-coupon': <DiscountCode />,
    'logo': <Logo />,
    'logo-white': <LogoWhite />,
    'logo-yellow': <LogoYellow style={{ height: '3.375rem' }} />,
    'header-logo-yellow': <LogoYellowHeader />,
    'facebook': <Facebook />,
    'instagram': <Instagram />,
    'twitter': <Twitter />,
    'youtube': <YouTube />,
    'charity': <Charity />,
    'home-address': <HomeAddress />,
    'customer-support': <CustomerSupport />,
    'arrow-down': <ArrowDown />,
    'arrow-down-small': <ArrowDownSmall />,
    'arrow-up-small': <ArrowUpSmall />,
    'desktop-map': <DesktopMap />,
    'desktop-media': <DesktopMedia />,
    'desktop-shopping-bag': <DesktopShoppingBag />,
    'desktop-scissors-fill': <DesktopScissorsFill />,
    'discount': <Discount />,
    'hamburger-menu': <Hamburger />,
    'end-note': <EndStack />,
    'close-icon': <Close />,
    'search-mob': <SearchMob />,
    'whatsapp': <WhatsApp />,
    'mail': <Mail />,
    'stars': <Stars />,
    'confirmation-graphic': <ConfirmationGraphic />,
    'calender': <Calender />,
    'secure': <Secure />,
    'no-exchange': <Exchange />,
    'easy-return': <Return />,
    'fast-delivery': <FastDelivery />,
    'COD': <COD />,
    'fi-service': <Service />,
    'Pay-on-delivery': <POD />,
    'wallet': <Wallet />,
    'home': <Home />,
    'user': <User />,
    'hot-deals': <HotDeals />,
    'hot-deals-yellow': <HotDealsYellow />,
    'profile': <Profile />,
    'coupons': <Coupons />,
    'myOrders': <MyOrders />,
    'rewards': <Rewards />,
    'logoIcon': <LogoIcon />,
    'rewardPoints': <Rewards />,
    'moneyStack': <MoneyStack />,
    'size-guide': <SizeGuide />,
    'login-frame': <LoginFrame />,
    'Track-shipment': <TrackShipment />,
    'Rating': <Rating />,
    'Delivery-Time': <DeliveryTime />,
    'Time-Dot': <TimeDot />,
    'For-Cat': <ForCat />,
    'For-Dog': <ForDog />,
    'Selected-Cat': <BlackCat />,
    'Selected-Dog': <BlackDog />,
    'QR-code': <QRCode />,
    'Smile': <Smile />,
    'Google': <Google />,
    'support': <Support />,
    'clear-filter': <ClearFilter />,
    'PersonIcon': <PersonIcon />,
    'Cart': <Cart />,
    'Call': <Call />,
    'Home-Service': <HomeService />,
    'Pricing': <Pricing />,
    'Pick-Drop': <PickDrop />,
    'Paw-Care': <PawCare />,
    'Stylist': <Stylist />,
    'Personalized': <Personalized />,
    'Consultations': <Consultations />,
    'Recommendations': <Recommendations />,
    'Walks': <Walks />,
    'Scheduling': <Scheduling />,
    'Safe-Secure': <SafeSecure />,
    'Pupdates': <Pupdates />,
    'Affordable': <Affordable />
};
const Icon = (props: IconPropsType) => {
    useCheckMobileScreen();
    const iconFromMap = IconMap[props.name];
    if (iconFromMap) {
        return iconFromMap;
    }

    return <>Not available</>;
};

export default Icon;
