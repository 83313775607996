import { Box, Button, Typography } from '@mui/material';
import {
    ButtonStyle,
    Container,
    Description,
    TopContainer,
    MainContainer,
    ReadMoreButton,
    TipBoxStyle,
    TipContainer,
    TipMainContainer,
    tipTitleStyle,
    Title,
    TitleBox,
    BottomContainer,
    BottomTitle,
    BottomLeft,
    BottomLeftImageBox,
    QRCodeBox,
    DownloadText,
    ImageBox,
} from './footerStyle';
import Furry from '../../../assets/images/furry.svg';
import AppStore from '../../../assets/images/AppStore.svg';
import PlayStore from '../../../assets/images/playStore.svg';
import AppBanner from '../../../assets/images/AppBanner.webp';
import AppImage from '../../../assets/images/AppImage.webp';
import AppImageMobile from '../../../assets/images/AppImageMobile.webp';
import { TipsData } from '../utils';
import Image from '../../../component/image/image';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Icon from '../../../component/icon';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';

export function Footer() {
    const isMobile = useCheckMobileScreen();
    return (
        <Box sx={MainContainer}>
            <Box sx={Container}>
                <Box sx={TopContainer}>
                    <Box sx={{ width: isMobile ? '100%' : '417.318px' }}>
                        <Box sx={TitleBox}>
                            <Typography sx={Title}>Furry Tales & Paw-some Tips</Typography>
                            <Image
                                src={Furry}
                                alt=""
                                width={'90px'}
                                height={'84px'}
                                style={{
                                    position: 'absolute',
                                    marginLeft: isMobile ? '' : '311px',
                                    marginTop: '-12px',
                                    right: isMobile ? '20px' : '',
                                }}
                            />
                        </Box>

                        <Typography sx={Description}>
                            Unleashing Pet Happiness and Enlightening Insights for Every Pet
                            Parent's Journey!
                        </Typography>
                        <Button
                            variant="contained"
                            sx={ButtonStyle}
                            onClick={() => window.open('https://blog.sniffynose.com/', '_blank')}
                        >
                            View all blogs
                        </Button>
                    </Box>
                    <Box sx={TipMainContainer}>
                        {TipsData.map((tip: any, index: any) => (
                            <Box sx={TipBoxStyle} key={index}>
                                <img
                                    src={tip.icon}
                                    alt={tip.title}
                                    style={{
                                        borderRadius: '12px',
                                        width: '152px',
                                        height: '92px',
                                    }}
                                />
                                <Box sx={TipContainer}>
                                    <Box>
                                        <Typography sx={tipTitleStyle}>{tip.title}</Typography>
                                    </Box>
                                    <Button
                                        sx={ReadMoreButton}
                                        onClick={() => window.open(`${tip.link}`, '_blank')}
                                    >
                                        Read now
                                        <ArrowForwardIosIcon
                                            sx={{
                                                fontSize: '1rem',
                                                color: '#0A69D9',
                                                paddingTop: '3px',
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={BottomContainer}>
                    <Box sx={BottomLeft}>
                        <Typography sx={BottomTitle}>Sniffynose is in your pockets now!</Typography>
                        <Box sx={BottomLeftImageBox}>
                            <Image
                                src={PlayStore}
                                alt=""
                                width={isMobile ? '135px' : ''}
                                height={isMobile ? '45px' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    window.open(
                                        'https://play.google.com/store/apps/details?id=com.sniffynose',
                                        '_blank'
                                    )
                                }
                            />
                            <Image
                                src={AppStore}
                                alt=""
                                width={isMobile ? '135px' : ''}
                                height={isMobile ? '45px' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    window.open('https://www.apple.com/in/app-store/', '_blank')
                                }
                            />
                        </Box>
                    </Box>
                    <Box sx={ImageBox}>
                        {!isMobile && (
                            <Box sx={QRCodeBox}>
                                <Box sx={{ transform: 'rotate(13deg)' }}>
                                    <Icon name="QR-code" />
                                </Box>
                                <Typography sx={DownloadText}>Scan to Download</Typography>
                            </Box>
                        )}
                        <Image
                            src={AppBanner}
                            alt=""
                            style={{
                                position: isMobile ? '' : 'absolute',
                                marginLeft: isMobile ? '' : '-155px',
                                borderRadius: '45px 45px 0 0',
                                boxShadow: isMobile
                                    ? '16.356px -12.815px 40.524px -20px rgba(0, 0, 0, 0.6)'
                                    : '80.309px -27.14px 47.305px -10px rgba(0, 0, 0, 0.6)',
                                marginTop: isMobile ? '' : '43px',
                                zIndex: isMobile ? 2 : '',
                            }}
                        />
                        {isMobile ? (
                            <Image
                                src={AppImageMobile}
                                alt=""
                                style={{
                                    position: 'absolute',
                                    marginTop: '65px',
                                    right: '20px',
                                    borderRadius: '0 0 2rem',
                                    zIndex: 1,
                                }}
                            />
                        ) : (
                            <Image src={AppImage} alt="" />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
