export const MainContainer = {
    maxWidth: '1440px',
    padding: '0 4rem',
    '@media (max-width: 1440px)': {
        maxWidth: '1240px',
    },
    '@media (max-width: 1200px)': {
        padding: 0,
        maxWidth: '1140px',
    },
    '@media (max-width: 1120px)': {
        padding: 0,
        maxWidth: '920px',
    },
    '@media (max-width: 768px)': {
        width: '100%',
    }
}

export const container = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderRadius: '32px',
    background: '#CAF2FF',
    padding: '73px 0 73px 83px',
    gap: '50px',
    marginTop: '100px',
    '@media (max-width: 768px)': {
        marginTop: '3rem',
        padding: '28px 16px',
        flexDirection: 'column',
        gap: '2rem',
    },
};

export const LeftContainer = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
};

export const Title = {
    color: '#14141E',
    textAlign: 'center',
    fontFamily: 'Riffic-Free-Bold',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.64px',
    paddingBottom: '5px',
    '@media (max-width: 768px)': {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
    },
};

export const DealEndBox = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '1.5rem',
    '@media (max-width: 768px)': {
        gap: '6px',
        marginTop: '1.25rem',
    },
};

export const DividerStyle = {
    width: '120px',
    height: '1.7px',
    opacity: 0.6,
    '@media (max-width: 768px)': {
        width: '80px',
        height: '1.065px',
    },
};

export const DealEnd = {
    color: '#0E323E',
    textAlign: 'center',
    fontFeatureSettings: "'liga' off",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    width: 'max-content',
    '@media (max-width: 768px)': {
        fontSize: '11.411px',
    },
};

export const TimeBox = {
    display: 'flex',
    gap: '1.1rem',
    alignItems: 'center',
    marginTop: '8px',
    '@media (max-width: 768px)': {
        gap: '12.9px',
        marginTop: '8px',
    },
};

export const TimeMainBox = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.52rem',
};

export const TimeStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Luckiest-Guy',
    fontSize: '24px',
    fontStyle: 'normal',
    width: '5rem',
    fontWeight: '400',
    borderRadius: '1rem',
    lineHeight: 'normal',
    padding: '1rem',
    letterSpacing: '5.52px',
    background: `linear-gradient(180deg, #17181A 0%, #3F4145 33%, #2D2E30 67%, #17181A 100%)`,
    '@media (max-width: 768px)': {
        letterSpacing: '4.14px',
        padding: '0.75rem',
        width: '4.5rem',
    },
};

export const ViewAllButton = {
    display: 'inline-flex',
    padding: '14px 14px 14px 20px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '12px',
    background: '#FFF',
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    marginTop: '2rem',
    boxShadow: 'none !important',
    '@media (max-width: 768px)': {
        fontSize: '16px',
        justifyContent: 'center',
        padding: '12px 14px 12px 20px',
        marginTop: '0',
        gap: '6px',
    },
    '&:hover': {
        background: '#FFF',
    },
};

export const TimeText = {
    color: '#0E323E',
    textAlign: 'center',
    fontFeatureSettings: "'liga' off",
    WebkitTextStrokeWidth: '0.43221932649612427',
    WebkitTextStrokeColor: '#B8EEFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '15.214px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
        fontSize: '11.411px',
        WebkitTextStrokeWidth: '0.3241645097732544',
    },
};

export const ProductTitle = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textAlign: 'start',
    '@media (max-width: 768px)': {
        fontSize: '0.875rem',
        fontWeight: 400,
    },
};

export const OptionsStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color: 'var(--Accent-Blue, #0A69D9)',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'start',
};

export const SellingPrice = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
    '@media (max-width: 768px)': {
        lineHeight: 'normal',
    },
};

export const originalPriceStyle = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    opacity: '0.4',
    lineHeight: '1.5rem',
    textDecoration: 'line-through',
    '@media (max-width: 768px)': {
        fontSize: '0.875rem',
    },
};

export const AddButton = {
    border: '1px solid var(--yellow-pri, #FFD600)',
    background: '#FFD600',
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Light',
    padding: '11px 16px',
    height: '40px',
    borderRadius: '0 0 1rem 1rem',
    '&:hover': {
        background: '#FFD600',
    },
};
