import { getDealsLabel } from './dealsNavigationLabel';

export const navItem = [
    {
        label: 'Products',
        icon: 'bag-fill-mob',
        path: '/products',
    },
    {
        label: 'Grooming',
        icon: 'scissors-fill-mob',
        path: '/service',
    },
    {
        label: 'Stores',
        icon: 'map-mob',
        path: '/location',
    },
    {
        label: 'Support',
        icon: 'media-mob',
        path: '/support',
    },
    {
        label: `${getDealsLabel()}`,
        icon: 'hot-deals',
        path: '/products/deals',
    },
];

export const desktopNavItem = [
    /*  {
    label: '',
    icon: 'logo-yellow',
    path: '/',
  }, */
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Products',
        icon: 'desktop-shopping-bag',
        path: '/products',
    },
    {
        label: 'Grooming',
        icon: 'desktop-scissors-fill',
        path: '/service',
    },
    {
        label: 'Stores',
        icon: 'desktop-map',
        path: '/location',
    },
    {
        label: 'Support',
        icon: 'desktop-media',
        path: '/support',
    },
    {
        label: `${getDealsLabel()}`,
        icon: 'hot-deals',
        path: '/products/deals',
    },
];

export const shopByBrands = [
    { label: 'Acana', path: '/products/?search=Acana' },
    { label: 'Royal Canin', path: '/products/?search=Royal Canin' },
    { label: 'CHIP CHOP', path: '/products/?search=CHIP CHOP' },
    { label: 'Furlicks', path: '/products/?search=Furlicks' },
    { label: 'Jerhigh', path: '/products/?search=Jerhigh' },
    { label: 'More', path: '/#Top-brands' },
];

export const FooterSections = [
    {
        title: 'At our Store',
        tags: [
            { label: 'Dogs', path: '/products/?search=Dogs' },
            { label: 'Cats', path: '/products/?search=Cats' },
            { label: 'Treats', path: '/products/?search=Treats' },
            { label: 'Toys', path: '/products/?search=Toys' },
            { label: 'Accessories', path: '/products/?search=Accessories' },
            { label: 'Best sellers', path: '/products/?search=Best sellers' },
        ],
    },
    {
        title: 'Shop by Brands',
        tags: shopByBrands,
    },
    {
        title: 'Company',
        tags: [
            { label: 'About us', path: '/' },
            { label: 'Careers', path: '/' },
            { label: 'Blogs', path: '/' },
            { label: 'Contact us', path: '/support' },
            { label: 'Store', path: '/location' },
        ],
    },
    {
        title: 'Quick paths',
        tags: [
            { label: 'My Orders', path: '/profile/orders' },
            { label: 'Track your order', path: '/' },
            { label: 'FAQs', path: '/' },
            { label: 'Rewards & offers', path: '/' },
            { label: 'Privacy Policy', path: '/privacy-policy' },
            { label: 'Terms & Conditions', path: '/terms-conditions' },
            { label: 'Payment terms', path: '/' },
        ],
    },
];
